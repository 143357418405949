.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  min-width: 0;
  border-radius: 5px;
  padding: 15% 0px 15% 9%;
}
.rootselected {
  background: #9ecef733;
}
.root:hover {
  background: #9ecef733;
}
.text {
  display: block;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  height: auto;
}
@media (max-width: 1440px) {
  .text {
    font-size: 1vw;
    width: 100%;
    min-width: 0;
  }
}
