.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 42px;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  min-width: 0;
  border-radius: 5px;
}
@media (max-width: 1440px) {
  .root {
    height: 2.45vw;
  }
}
.root:hover {
  background: #505df7;
}
.freeBox__oaPtr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  margin: 0px 0px 0px 10px;
}
.slotTargetCompanyName {
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 1440px) {
  .slotTargetCompanyName {
    font-size: 0.81vw;
  }
}
.root:hover .slotTargetCompanyName {
  color: #ffffff;
}
.freeBox__cIweQ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  margin: 2px 0px 0px 10px;
}
.slotTargetEmailAddress {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 1440px) {
  .slotTargetEmailAddress {
    font-size: 0.7vw;
  }
}
.root:hover .slotTargetEmailAddress {
  color: #ffffff;
}
