.root {
  background: #3555ff;
  width: 269px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  padding-bottom: 0px;
  padding-left: 0px;
  position: relative;
  margin-right: 0px;
  overflow: visible;
  min-height: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
@media (max-width: 1600px) {
  .root {
    width: 15vw;
    padding-top: 7.6vw;
  }
}
@media (max-width: 1440px) {
  .root {
    padding-top: 7.6vw;
    width: 15vw;
  }
}
.dashboardButton {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  background: none;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .dashboardButton {
    height: 6.5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .dashboardButton {
    height: 7vw;
    flex-shrink: 0;
  }
}
.root .dashboardButton:hover {
  background: #ffffff21;
}
.img___8XYyS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 17px;
  height: 17px;
  display: block !important;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-left: 66px;
  flex-shrink: 0;
}
.img___8XYyS > picture > img {
  object-fit: cover;
}
@media (max-width: 1600px) {
  .img___8XYyS {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .img___8XYyS {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
.title {
  display: block;
  font-size: 16.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  align-self: center;
  padding-left: 23px;
}
@media (max-width: 1600px) {
  .title {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
@media (max-width: 1440px) {
  .title {
    margin-bottom: 0px;
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
.paymentsButton {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .paymentsButton {
    height: 6.5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .paymentsButton {
    height: 7vw;
    flex-shrink: 0;
  }
}
.root .paymentsButton:hover {
  background: #ffffff21;
}
.img___8Vi8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 17px;
  height: 17px;
  display: block !important;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-left: 66px;
  flex-shrink: 0;
}
.img___8Vi8 > picture > img {
  object-fit: cover;
}
@media (max-width: 1600px) {
  .img___8Vi8 {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .img___8Vi8 {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
.title2 {
  display: block;
  font-size: 16.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  align-self: center;
  padding-left: 23px;
}
@media (max-width: 1600px) {
  .title2 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
@media (max-width: 1440px) {
  .title2 {
    font-size: 0.9vw;
    padding-bottom: 0px;
    padding-left: 1.5vw;
  }
}
.contractsButton {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .contractsButton {
    height: 6.5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .contractsButton {
    height: 7vw;
    flex-shrink: 0;
  }
}
.root .contractsButton:hover {
  background: #ffffff21;
}
.img__spBda {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 17px;
  height: 17px;
  display: block !important;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-left: 66px;
  flex-shrink: 0;
}
.img__spBda > picture > img {
  object-fit: cover;
}
@media (max-width: 1600px) {
  .img__spBda {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .img__spBda {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
.title3 {
  display: block;
  font-size: 16.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  align-self: center;
  padding-left: 23px;
}
@media (max-width: 1600px) {
  .title3 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
@media (max-width: 1440px) {
  .title3 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
.clientsButton {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border-width: 0px;
}
@media (max-width: 1600px) {
  .clientsButton {
    height: 6.5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .clientsButton {
    height: 7vw;
    flex-shrink: 0;
  }
}
.root .clientsButton:hover {
  background: #ffffff21;
}
.img___3EJnj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 17px;
  height: 17px;
  display: block !important;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-left: 66px;
  flex-shrink: 0;
}
.img___3EJnj > picture > img {
  object-fit: cover;
}
@media (max-width: 1600px) {
  .img___3EJnj {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .img___3EJnj {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
.title4 {
  display: block;
  font-size: 16.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  align-self: center;
  padding-left: 23px;
  height: auto;
}
@media (max-width: 1600px) {
  .title4 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
.settingsButton {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: none;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .settingsButton {
    height: 6.5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .settingsButton {
    height: 7vw;
    flex-shrink: 0;
  }
}
.root .settingsButton:hover {
  background: #ffffff21;
}
.img___6Cpce {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 17px;
  height: 17px;
  display: block !important;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  margin-left: 66px;
  flex-shrink: 0;
}
.img___6Cpce > picture > img {
  object-fit: cover;
}
@media (max-width: 1600px) {
  .img___6Cpce {
    margin-left: 3.8vw;
  }
}
@media (max-width: 1440px) {
  .img___6Cpce {
    width: 1.04vw;
    height: 1.04vw;
    margin-left: 3.8vw;
    flex-shrink: 0;
  }
}
.title5 {
  display: block;
  font-size: 16.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  align-self: center;
  padding-left: 23px;
  height: auto;
}
@media (max-width: 1600px) {
  .title5 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
@media (max-width: 1440px) {
  .title5 {
    font-size: 0.9vw;
    padding-left: 1.5vw;
  }
}
