.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.testInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 17vw;
  height: 3vw;
  flex-shrink: 0;
  margin: 200px 0px 0px;
}
.submitButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 4vw;
  height: 4vw;
  max-width: 100%;
  background: #121375;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 8px;
}
.tempStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20vw;
  height: 100%;
  max-width: 100%;
  min-height: 0;
  padding: 8px;
}
