.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 1);
  width: 75px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border-width: 0px;
}
.root:hover {
  background: #000000;
}
.root:active {
  background: #000000;
  box-shadow: inset 0px 0px 0px 1px #000000;
  border: 1px solid #d2d2d2;
}
.text {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
}
.root:hover .text {
  color: #ffffff;
}
.root:active .text {
  color: #ffffff;
}
