.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 516px;
  height: 27px;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0px;
  padding-top: 0px;
}
