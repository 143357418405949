.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__jh8Rt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__hifl4 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__sKaGr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__gasrU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__yTt94 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__rgvXt {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__xkug2 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__fx0To {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  height: 1em;
  flex-shrink: 0;
}
.svg__jaeU4 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__vsAv3 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__h8K3I {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__qAms {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__toZs6 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__fzI3O {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox__bIYhb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__bIYhb > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__bIYhb > :global(.__wab_flex-container) > *,
.freeBox__bIYhb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bIYhb > :global(.__wab_flex-container) > picture > img,
.freeBox__bIYhb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.setupDetailsText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.accountDetailsText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___8FkxN {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__y8XvY {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__oR2XO {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__w2780 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__a83K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 573px;
  font-size: 22px;
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  margin-right: 0px;
  text-decoration-line: none;
  min-width: 0;
}
.text__ypBuD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 573px;
  font-size: 17px;
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  color: #090909;
  line-height: 2;
  font-weight: 500;
  margin-right: 0px;
  padding-bottom: 0px;
  min-width: 0;
}
.ol {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 30px;
  margin-bottom: 20px;
}
.text___8ZmiJ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 573px;
  font-size: 16px;
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 600;
  margin-right: 0px;
  margin-bottom: 20px;
}
.controllerStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
  margin: 0px 0px 20px;
}
.controllerStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.controllerStack > :global(.__wab_flex-container) > *,
.controllerStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.controllerStack > :global(.__wab_flex-container) > picture > img,
.controllerStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.controllerCard:global(.__wab_instance) {
  margin-top: calc(0px + 20px) !important;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.addNew {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 187px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 0px;
  cursor: pointer;
  margin-bottom: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.addNew > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 13px);
  width: calc(100% + 13px);
}
.addNew > :global(.__wab_flex-container) > *,
.addNew > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.addNew > :global(.__wab_flex-container) > picture > img,
.addNew
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 13px;
}
.root .addNew:hover {
  background: #3555ffd4;
}
.root .addNew:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__nv3QU {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
  margin-right: 0px;
}
.text__lnJj8 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.addControllerStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  overflow: visible;
  margin-top: -35px;
  margin-bottom: 0px;
  min-width: 0;
  padding: 0px 0px 0px 10px;
}
.freeBox___2J1Z6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__mYuNr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__xtUPk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox___33Dq8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text___0TVms {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__oTJyP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___5Otb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectControllerType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__oMGlv:global(.__wab_instance) {
  position: relative;
}
.text__jTnQ {
  padding-bottom: 0px;
}
.option__wt3Bv:global(.__wab_instance) {
  position: relative;
}
.option__vw8Ri:global(.__wab_instance) {
  position: relative;
}
.deleteStack {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  left: auto;
  top: 18px;
  right: 50px;
  color: #3555ff;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.freeBox__xDHe2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___93MPa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.jobTitle {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__iiOvc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__gsizn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.phoneNumber {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox___3AsZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__wsAw7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.email {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__tbBsT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__h5JPf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.countrySelect:global(.__wab_instance) {
  max-width: 100%;
  width: 462px;
  margin-top: 0px;
  height: 41px;
  flex-shrink: 0;
}
.option__sRYvl:global(.__wab_instance) {
  position: relative;
}
.text__jcoWh {
  padding-bottom: 0px;
}
.option__rcDyJ:global(.__wab_instance) {
  position: relative;
}
.option__fXn44:global(.__wab_instance) {
  position: relative;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option___1YPd4:global(.__wab_instance) {
  position: relative;
}
.option__spIwr:global(.__wab_instance) {
  position: relative;
}
.option___9Sz9P:global(.__wab_instance) {
  position: relative;
}
.option___2NzBl:global(.__wab_instance) {
  position: relative;
}
.option___7AMht:global(.__wab_instance) {
  position: relative;
}
.option__sOkAn:global(.__wab_instance) {
  position: relative;
}
.option__sSnsx:global(.__wab_instance) {
  position: relative;
}
.option__xGqe2:global(.__wab_instance) {
  position: relative;
}
.option__j4LFm:global(.__wab_instance) {
  position: relative;
}
.option__kyStV:global(.__wab_instance) {
  position: relative;
}
.option__pf3LP:global(.__wab_instance) {
  position: relative;
}
.option__jCXw:global(.__wab_instance) {
  position: relative;
}
.option__tpmc3:global(.__wab_instance) {
  position: relative;
}
.option__hFUnF:global(.__wab_instance) {
  position: relative;
}
.option__oDtdj:global(.__wab_instance) {
  position: relative;
}
.option__eDDan:global(.__wab_instance) {
  position: relative;
}
.option___01DzZ:global(.__wab_instance) {
  position: relative;
}
.option__xFhdp:global(.__wab_instance) {
  position: relative;
}
.option__gYOrV:global(.__wab_instance) {
  position: relative;
}
.option__nIj2D:global(.__wab_instance) {
  position: relative;
}
.option__pFqLk:global(.__wab_instance) {
  position: relative;
}
.option__rw5L9:global(.__wab_instance) {
  position: relative;
}
.option___7Rune:global(.__wab_instance) {
  position: relative;
}
.option__akCkt:global(.__wab_instance) {
  position: relative;
}
.option___3RaRa:global(.__wab_instance) {
  position: relative;
}
.option__sqhx:global(.__wab_instance) {
  position: relative;
}
.option__eZnR:global(.__wab_instance) {
  position: relative;
}
.option__qb0La:global(.__wab_instance) {
  position: relative;
}
.option__xlp4Z:global(.__wab_instance) {
  position: relative;
}
.option___9Ebxl:global(.__wab_instance) {
  position: relative;
}
.option__feyvm:global(.__wab_instance) {
  position: relative;
}
.option__rEyKz:global(.__wab_instance) {
  position: relative;
}
.option__fFTj:global(.__wab_instance) {
  position: relative;
}
.option__ulQq8:global(.__wab_instance) {
  position: relative;
}
.option___06IKc:global(.__wab_instance) {
  position: relative;
}
.option__o22P4:global(.__wab_instance) {
  position: relative;
}
.option__aYrk3:global(.__wab_instance) {
  position: relative;
}
.option__oQ3CQ:global(.__wab_instance) {
  position: relative;
}
.option__bofbb:global(.__wab_instance) {
  position: relative;
}
.option___3C1PT:global(.__wab_instance) {
  position: relative;
}
.option__ajLfn:global(.__wab_instance) {
  position: relative;
}
.option__fbFjY:global(.__wab_instance) {
  position: relative;
}
.option__exR3J:global(.__wab_instance) {
  position: relative;
}
.option__emO9S:global(.__wab_instance) {
  position: relative;
}
.option__vhSg:global(.__wab_instance) {
  position: relative;
}
.option__d7CZt:global(.__wab_instance) {
  position: relative;
}
.option__oCnu0:global(.__wab_instance) {
  position: relative;
}
.option__qdXV:global(.__wab_instance) {
  position: relative;
}
.option__gNqi:global(.__wab_instance) {
  position: relative;
}
.option__qgtW:global(.__wab_instance) {
  position: relative;
}
.option__e50Pa:global(.__wab_instance) {
  position: relative;
}
.option___9W52O:global(.__wab_instance) {
  position: relative;
}
.option__ucDe9:global(.__wab_instance) {
  position: relative;
}
.option__dVgpO:global(.__wab_instance) {
  position: relative;
}
.option__b05YZ:global(.__wab_instance) {
  position: relative;
}
.option__zkzyw:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__ui6O {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__pOiN9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ownership {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__au4Lz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__ihdcv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.ssnStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__tS1Zm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.passportStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__bgkS9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.passport {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.controllerStackError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  color: #ff0000;
}
@media (max-width: 1370px) {
  .controllerStackError {
    font-size: 15px;
    margin-left: 0px;
    margin-top: 35px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -30px;
    align-self: flex-start;
  }
}
.ageError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ageError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.ssnError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ssnError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.submitButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 187px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 51px;
  cursor: pointer;
  margin-bottom: 54px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .submitButton:hover {
  background: #3555ffd4;
}
.root .submitButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__xsRqg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.text__x0LTk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 573px;
  font-size: 22px;
  margin-top: 50px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  margin-right: 0px;
  text-decoration-line: none;
  min-width: 0;
}
.certificationText {
  position: relative;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  max-width: 573px;
}
.slotTargetCertificationText {
  font-size: 17px;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.text__qj2J {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  margin: 33px 0px 5px 4px;
}
.signature {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 261px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  font-family: "Inter", sans-serif;
  background: #b51b1b00;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.text__yY73C {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  margin: 33px 0px 5px 4px;
}
.currentDate {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 261px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.errorText {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  margin-top: 35px;
  margin-bottom: -30px;
  margin-left: 0px;
  padding: 0px;
}
@media (max-width: 1370px) {
  .errorText {
    position: relative;
    align-self: flex-start;
    margin-top: 35px;
    margin-bottom: -30px;
    margin-left: 0px;
  }
}
.errorSlot1 {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1370px) {
  .errorSlot1 {
    width: auto;
    height: auto;
    max-width: 800px;
  }
}
.slotTargetErrorSlot {
  font-size: 15px;
  color: #ff0000;
}
@media (max-width: 1370px) {
  .slotTargetErrorSlot {
    font-size: 13.5px;
  }
}
.text__pKmZ {
  margin-top: 0px;
  color: #ff0000;
  font-size: 15px;
}
.continueButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  margin: 49px 0px 50px;
}
