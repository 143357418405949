.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 5px 0px;
  border-bottom: 0px solid #d3d3d3e6;
}
@media (max-width: 1440px) {
  .root {
    max-width: none;
  }
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  margin-left: 12px;
}
@media (max-width: 1440px) {
  .text {
    font-size: 1.52vw;
    margin-left: 0.8vw;
  }
}
.closeButton {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-left: 451px;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  color: #3555ff;
  font-weight: 700;
  align-self: center;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .closeButton {
    font-size: 1.52vw;
    margin-left: 451px;
  }
}
