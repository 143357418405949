.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(186, 186, 186, 1);
  width: 150px;
  height: 38px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 47px;
}
.root:hover {
  background: #efefef;
}
.root:active {
  background: #efefef;
  box-shadow: inset 0px 0px 0px 1px #464646;
  border-width: 1px;
}
.text {
  display: block;
  font-size: 0.75vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
}
