.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  min-width: 0;
}
.freeBox___040Dv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___9142 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text___955LA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__na9Cl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__tLilP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__wvp3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px;
}
.clientKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 584px;
  margin-top: 6px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox___0E69R {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__ynk39 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__xEkqu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px;
}
.webHookKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 584px;
  margin-top: 6px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__yJM9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__r221Z {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__jiDiW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__eCo9H {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__yUKc {
  width: 582px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__a2Lny {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 6px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.devClientKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 584px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__iqeO6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__vX2Du {
  width: 584px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__p6ZWs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 10px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.devWebHookKey:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 584px;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox___1Xzzr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: 10px;
  min-width: 0;
  padding: 17px 8px 8px;
}
.saveBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 78px;
  margin-right: 8px;
  flex-shrink: 0;
}
.svg__oUpwi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jBKbo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
