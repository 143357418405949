.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__wWbs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text___9JwSg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__hgzP {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__bOWy9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__qQ9Gx {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___6DhsJ {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__ketoZ {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__kwPPb {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___0TUej {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__yi9Iy {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__hqG5T {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__mmavC {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__aXcr0 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__xxgsf {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__b7HpW {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__e20N0 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__dleta {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__hwBjW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__hwBjW > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__hwBjW > :global(.__wab_flex-container) > *,
.freeBox__hwBjW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hwBjW > :global(.__wab_flex-container) > picture > img,
.freeBox__hwBjW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__zjHhr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__k17X4 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__vEfG {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__h22BM {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__h21Bl {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1051px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  margin-right: 0px;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__eHt4U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 40px 0px 20px;
}
.text__qdJfH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  color: #363636;
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 0px;
}
