.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 57%;
  max-width: 100%;
  background: #0028ff00;
  position: relative;
  min-width: 0;
  padding: 0px;
  border-top: 0px solid #dedfea;
  border-right: 0px solid #dedfea;
  border-left: 0px solid #dedfea;
}
