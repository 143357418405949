.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #eef2ff;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 179px;
  margin-top: 50px;
}
.img > picture > img {
  object-fit: cover;
}
@media (max-width: 1370px) {
  .img {
    margin-top: 35px;
  }
}
@media (max-width: 414px) {
  .img {
    width: 150px;
    margin-top: 30px;
  }
}
.text__aR8P {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #181818;
  margin-top: 32px;
  font-size: 38px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1370px) {
  .text__aR8P {
    margin-top: 25px;
  }
}
@media (max-width: 414px) {
  .text__aR8P {
    font-size: 28px;
    margin-top: 20px;
  }
}
.freeBox__y2Yi9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 50px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox__y2Yi9 {
    width: 330px;
    margin-top: 29px;
  }
}
.text__dg3Nv {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__dg3Nv {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__dg3Nv {
    font-size: 13.7px;
  }
}
.password:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .password:global(.__wab_instance) {
    width: 330px;
    flex-shrink: 0;
  }
}
.freeBox__ulE7F {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 400px;
  margin-top: 30px;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox__ulE7F {
    width: 330px;
  }
}
.confirmPasswordText {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .confirmPasswordText {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .confirmPasswordText {
    font-size: 13.7px;
  }
}
.confirmPassword:global(.__wab_instance) {
  margin-top: 21px;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .confirmPassword:global(.__wab_instance) {
    width: 330px;
    flex-shrink: 0;
  }
}
.customErrorInstance:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 17px;
  margin-right: 0px;
  margin-left: 11px;
}
.text__kRr4L {
  color: #ff0000;
  font-size: 15px;
  line-height: 1.6;
}
@media (max-width: 414px) {
  .text__kRr4L {
    font-size: 13.7px;
  }
}
.submitButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 57px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  margin-top: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 414px) {
  .submitButton {
    width: 340px;
    height: 52px;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-shrink: 0;
  }
}
.root .submitButton:hover {
  background: #3555ffd4;
}
.root .submitButton:active {
  background: #3555ffd4;
  border: 1px solid #ffffff;
}
.text__cqo5T {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
@media (max-width: 414px) {
  .text__cqo5T {
    font-size: 17px;
  }
}
