.input {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 0, 0, 0);
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  padding-left: 10px;
  font-size: 15.4px;
  font: "Inter";
  color: #303030;
}

.input2 {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 0, 0, 0);
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  padding-left: 10px;
  font-size: 16.4px;
  font: "Inter";
  color: #303030;
}

.input:focus {
  outline: none !important;
  border: 1px solid rgba(255, 0, 0, 0);
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
}

textarea:focus {
  outline: none !important;
  border-color: #719ece00;
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
}

.dateBox {
  height: 39px !important;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 0, 0, 0);
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  padding-left: 10px;
  font-size: 15.5px !important;
  font: "Inter" !important;
}

.dateBox2 {
  height: 41px !important;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 0, 0, 0);
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  padding-left: 10px;
  font-size: 15.5px !important;
  font: "Inter" !important;
}

.ais-SearchBox-form:before {
  background: transparent
    url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
    repeat scroll 0 0;
  content: "";
  height: 1rem;
  left: 1rem;
  position: absolute;
  top: 32%;
  width: 1rem;
}

/* .ais-SearchBox-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 1px solid #d6d6e7;
    border-radius: 3px;
    box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 30%);
    caret-color: #5a5e9a;
    color: #23263b;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    font: inherit;
    max-width: 100%;
    padding-left: 2.5rem;
} */

.ais-SearchBox-input {
  height: 41px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 0, 0, 0);
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  padding-left: 2.6rem;
  font-size: 16px;
  font: "open sans";
}

@media only screen and (max-width: 1440px) {
  .input {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #a3a3a3;
    padding-left: 10px;
    font-size: 1.05vw;
    font: "Inter";
    color: #303030;
  }

  .input2 {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #a3a3a3;
    padding-left: 10px;
    font-size: 0.97vw;
    font: "Inter";
    color: #303030;
  }

  .dateBox {
    height: 2.29vw !important;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #a3a3a3;
    padding-left: 10px;
    font-size: 0.893vw !important;
    font: "Inter" !important;
  }

  .dateBox2 {
    height: 2.29vw !important;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #a3a3a3;
    padding-left: 10px;
    font-size: 0.893vw !important;
    font: "Inter" !important;
  }

  .ais-SearchBox-input {
    height: 2.29vw;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #b7b6b6;
    padding-left: 2.6rem;
    font-size: 0.97vw;
    font: "Inter";
  }

  .ais-SearchBox-form:before {
    background: transparent
      url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
      repeat scroll 0 0;
    content: "";
    height: 1rem;
    left: 1rem;

    position: absolute;
    top: 25%;
    width: 1rem;
  }
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-submitIcon {
  width: 14px;
  height: 14px;
}
