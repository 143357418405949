.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  padding: 0px;
}
.root:hover {
  background: #3555ffd4;
}
.root:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
