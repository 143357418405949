.root {
  width: auto;
  height: auto;
  max-width: 800px;
  position: relative;
  display: inline-flex;
  flex-direction: row;
}
.slotTargetCustomErrorText {
  font-size: 15px;
  color: #ff0000;
}
@media (max-width: 1370px) {
  .slotTargetCustomErrorText {
    font-size: 13.5px;
  }
}
.text__f6HlX {
  color: #ff0000;
  font-size: 15px;
}
