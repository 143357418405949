.root {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  width: 295px;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  padding: 2px;
  border-right: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
@media (max-width: 1440px) {
  .root {
    width: 18vw;
  }
}
@media (max-width: 1440px) {
  .root > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 5px);
    height: calc(100% + 5px);
  }
}
@media (max-width: 1440px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 5px;
  }
}
.roothidden {
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  background: #ffffff00;
}
