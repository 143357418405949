.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__tvf7S {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox__tvf7S {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__uHq6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 850px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  margin-top: 50px;
  padding: 0px 8px;
}
@media (max-width: 1440px) {
  .freeBox__uHq6 {
    width: 52vw;
    max-width: 833px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin: 3.4% 0px 0%;
  }
}
.sheet2 {
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 100%;
  height: 385px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 1.5%;
  flex-shrink: 0;
  border-radius: 8px;
}
@media (max-width: 1440px) {
  .sheet2 {
    height: 22.2vw;
    margin-top: 0%;
    flex-shrink: 0;
  }
}
.figmaPaste {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  margin: 0%;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 1%);
  height: calc(100% + 1%);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1%;
}
.freeBox__lSzho {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 64px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dedfea;
}
@media (max-width: 1440px) {
  .freeBox__lSzho {
    height: 3.66vw;
    flex-shrink: 0;
    padding: 0px;
  }
}
.title4 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title4 {
    font-size: 1.13vw;
  }
}
.title3 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 5%;
  margin-top: calc(25px + 1%) !important;
  margin-bottom: 0%;
}
@media (max-width: 1440px) {
  .title3 {
    font-size: 1.11vw;
    margin-top: calc(1.5vw + 1%) !important;
  }
}
.selectedClient {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 305px;
  height: auto;
  max-width: 100%;
  margin-left: 5%;
  margin-top: calc(2% + 1%) !important;
  padding: 0px;
}
@media (max-width: 1440px) {
  .selectedClient {
    margin-top: calc(2% + 1%) !important;
  }
}
.freeBox__gD0Nn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  background: #ffffff00;
  min-height: 0;
  padding: 0px;
}
.slotTargetCompanySelected {
  font-weight: 700;
}
.text___4E2TB {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
}
.emailSelected {
  margin-top: 4%;
  display: flex;
  flex-direction: row;
}
.slotTargetEmailSelected {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 1440px) {
  .slotTargetEmailSelected {
    font-size: 0.94vw;
  }
}
.deleteSelection {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: #3555ff;
  cursor: pointer;
  margin-left: 6%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .deleteSelection {
    font-size: 1vw;
  }
}
.searchRectangle:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(2% + 1%) !important;
  margin-left: 5%;
  margin-bottom: 0%;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .searchRectangle:global(.__wab_instance) {
    left: auto;
    top: auto;
    margin-top: calc(1.2vw + 1%) !important;
    flex-shrink: 0;
  }
}
.menuSelectStack:global(.__wab_instance) {
  position: absolute;
  top: 46%;
  left: 5.69%;
  z-index: 1;
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .menuSelectStack:global(.__wab_instance) {
    top: 45%;
  }
}
.title2 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 5%;
  margin-top: calc(45px + 1%) !important;
}
@media (max-width: 1440px) {
  .title2 {
    font-size: 1.11vw;
    margin-bottom: 0.85%;
    margin-top: calc(2.55vw + 1%) !important;
  }
}
@media (max-width: 1370px) {
  .title2 {
    margin-top: calc(2.55vw + 1%) !important;
  }
}
.rectangle9 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #ffffff00;
  width: 305px;
  height: 39px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 5%;
  margin-top: calc(2% + 1%) !important;
  flex-shrink: 0;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .rectangle9 {
    width: 17.9vw;
    height: 2.29vw;
    margin-top: calc(1vw + 1%) !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .rectangle9 {
    margin-top: calc(1vw + 1%) !important;
  }
}
.contractTitleInput:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.freeBox__u8PC {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #dedfea;
  min-height: 405px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__u8PC {
    min-height: 23vw;
    margin-top: 5.3vw;
  }
}
.freeBox__g4Rm9 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 64px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dedfea;
}
@media (max-width: 1440px) {
  .freeBox__g4Rm9 {
    height: 3.66vw;
    flex-shrink: 0;
    padding: 0px;
  }
}
.title10 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title10 {
    font-size: 1.13vw;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}
.text__x9TJb {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #1f1f1f;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 35px;
  text-align: center;
}
@media (max-width: 1440px) {
  .text__x9TJb {
    margin-top: 1.7vw;
    margin-bottom: 1.8vw;
    max-width: none;
    font-size: 0.89vw;
  }
}
.moreMilestonesStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  min-width: 0;
  padding: 8px 12px;
}
@media (max-width: 1440px) {
  .moreMilestonesStack {
    padding: 0.5vw 0.7vw;
  }
}
.milestoneEntry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.addMilestoneButton {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: auto;
  height: auto;
  align-self: flex-start;
  margin-left: 67px;
  margin-top: 15px;
  margin-bottom: 50px;
  padding-left: 0px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .addMilestoneButton {
    font-size: 0.8vw;
    padding-top: 0.1vw;
    padding-bottom: 0.1vw;
    padding-right: 0vw;
    margin-left: 4vw;
    margin-top: 0.85vw;
    margin-bottom: 3.4vw;
  }
}
.freeBox___6RkTi {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #dedfea;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 100px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___6RkTi {
    height: auto;
    margin-top: 5.8vw;
  }
}
.freeBox__hy5BH {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border-bottom: 1px solid #dedfea;
}
@media (max-width: 1440px) {
  .freeBox__hy5BH {
    height: 3.6vw;
    align-items: center;
    justify-content: center;
    padding-top: 0%;
    padding-bottom: 0%;
    max-width: none;
    flex-shrink: 0;
  }
}
.title11 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title11 {
    font-size: 1.13vw;
    margin-top: 2%;
    margin-bottom: 2%;
    left: auto;
    top: auto;
  }
}
.title12 {
  display: block;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 2.2;
  color: #1f1f1f;
  width: 85%;
  height: 100%;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 20px;
  align-self: center;
  text-align: center;
  min-height: 0;
}
@media (max-width: 1440px) {
  .title12 {
    font-size: 0.77vw;
    text-align: center;
    margin-right: 0%;
    margin-left: 0%;
    line-height: 2.2;
    height: auto;
    width: 85%;
    margin-top: 1.28vw;
  }
}
.freeBox__pXzkh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 295px;
  max-width: 100%;
  background: #44c0ff00;
  margin-top: 0%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px 8px 20px;
}
@media (max-width: 1440px) {
  .freeBox__pXzkh {
    align-items: center;
    justify-content: center;
    height: 17vw;
    padding-bottom: 0.85vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    flex-shrink: 0;
  }
}
.freeBox__dxspa {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 365px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  flex-shrink: 0;
  padding: 6px 8px 12px;
}
@media (max-width: 1440px) {
  .freeBox__dxspa {
    width: 50%;
    height: auto;
    padding: 0%;
  }
}
.title17 {
  display: block;
  font-size: 15px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 2;
  color: #1f1f1f;
  width: 349px;
  height: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-height: 0;
  margin: 15px 0px;
}
@media (max-width: 1440px) {
  .title17 {
    font-size: 0.85vw;
    margin-top: 0vw;
    margin-bottom: 1.7vw;
    left: auto;
    top: auto;
    height: 4vw;
    width: 100%;
    max-width: 19vw;
    min-width: 0;
    flex-shrink: 0;
  }
}
.uploadButton {
  background: #3555ff;
  box-shadow: none;
  width: 177px;
  height: 50px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .uploadButton {
    justify-content: center;
    align-items: center;
    width: 10.3vw;
    height: 2.98vw;
    margin-top: 0%;
    margin-bottom: 0%;
    flex-shrink: 0;
  }
}
.root .uploadButton:hover {
  background: #3555ffd1;
}
.root .uploadButton:active {
  background: #3555ffd1;
  border-width: 1px;
  border-style: solid;
}
.text__egVpj {
  display: block;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  left: 58px;
  top: 15px;
  align-self: center;
}
@media (max-width: 1440px) {
  .text__egVpj {
    font-size: 1.02vw;
    position: relative;
    left: auto;
    top: auto;
  }
}
.freeBox__uya65 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
@media (max-width: 1440px) {
  .freeBox__uya65 {
    margin-top: 2vw;
  }
}
.slotTargetContractUploadText {
  font-size: 14px;
  text-align: center;
}
@media (max-width: 1440px) {
  .slotTargetContractUploadText {
    font-size: 0.9vw;
  }
}
.freeBox___5AhiK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 5px;
  padding: 8px 14px 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox___5AhiK {
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-right: 0.9vw;
    margin-right: 0.4vw;
  }
}
.title5 {
  display: block;
  font-size: 17px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-right: 0%;
}
@media (max-width: 1440px) {
  .title5 {
    font-size: 1.11vw;
  }
}
.freeBox___5Jpnn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 365px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  flex-shrink: 0;
  padding: 8px 8px 0px;
}
@media (max-width: 1440px) {
  .freeBox___5Jpnn {
    width: 48.18%;
    height: 100%;
    justify-content: center;
    align-items: center;
    min-height: 0;
    padding: 0% 1vw 0% 0%;
  }
}
.title19 {
  display: block;
  font-size: 15px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 2;
  color: #1f1f1f;
  width: 299px;
  height: 100%;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: 0;
}
@media (max-width: 1440px) {
  .title19 {
    font-size: 0.85vw;
    margin-top: 0%;
    margin-bottom: 0.85vw;
    height: auto;
    width: 20vw;
  }
}
.rectangle26 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.259);
  width: 320px;
  height: 114px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 8px;
  margin-bottom: 0%;
  align-self: center;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .rectangle26 {
    height: 7.65vw;
    width: 20vw;
    flex-shrink: 0;
  }
}
.contractDescription {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 14.5px;
  position: relative;
  width: 339px;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-height: 0;
  border-radius: 10px;
  padding: 5px 2px 2px 12px;
  border: 0px solid #d3d3d3;
}
@media (max-width: 1440px) {
  .contractDescription {
    width: 100%;
    font-size: 0.9vw;
    min-width: 0;
  }
}
.freeBox__uBrfG {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 10px 0px 8px;
}
@media (max-width: 1440px) {
  .freeBox__uBrfG {
    height: auto;
    padding-top: 0px;
  }
}
.advancedOptionsInstance:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: 100px;
  width: 100%;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .advancedOptionsInstance:global(.__wab_instance) {
    margin-top: 5.9vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .advancedOptionsInstance:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.advancedOptionsStack {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 100%;
  height: 420px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  padding-bottom: 0px;
  margin-top: 0%;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 0px 0px 8px 8px;
}
@media (max-width: 1440px) {
  .advancedOptionsStack {
    height: 26vw;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-shrink: 0;
  }
}
.freeBox___58Nl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0px;
  margin-top: 15px;
  min-width: 0;
  padding: 8px;
}
.freeBox___58Nl > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10%);
  height: calc(100% + 10%);
}
.freeBox___58Nl > :global(.__wab_flex-container) > *,
.freeBox___58Nl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___58Nl > :global(.__wab_flex-container) > picture > img,
.freeBox___58Nl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10%;
}
@media (max-width: 1440px) {
  .freeBox___58Nl {
    margin-top: 1vw;
    padding: 0.5vw;
  }
}
@media (max-width: 1440px) {
  .freeBox___58Nl > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.title26 {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(2% + 10%) !important;
  margin-bottom: 2%;
  padding-right: 0px;
  text-align: center;
}
@media (max-width: 1440px) {
  .title26 {
    font-size: 1vw;
    margin-top: calc(2% + 10%) !important;
  }
}
.achSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  margin-left: 0%;
}
.text__ec5AZ {
  padding-left: 0px;
}
.cardSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  margin-left: 0%;
}
.text___04O2W {
  padding-left: 0px;
}
.cardSurchargeSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  margin-left: 0%;
}
.text__qriIa {
  padding-left: 0px;
}
.freeBox__nxtZl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-right: 0px;
  margin-top: 15px;
  min-width: 0;
  padding: 8px;
}
.freeBox__nxtZl > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10%);
  height: calc(100% + 10%);
}
.freeBox__nxtZl > :global(.__wab_flex-container) > *,
.freeBox__nxtZl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nxtZl > :global(.__wab_flex-container) > picture > img,
.freeBox__nxtZl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10%;
}
@media (max-width: 1440px) {
  .freeBox__nxtZl {
    margin-top: 1vw;
    padding: 0.5vw;
  }
}
.title27 {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(2% + 10%) !important;
  margin-bottom: 2%;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title27 {
    font-size: 1vw;
    margin-top: calc(2% + 10%) !important;
  }
}
.paymentReminderSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.text__vxrEe {
  width: auto;
  height: auto;
  padding-right: 0px;
}
.paymentReminderStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 295px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  left: auto;
  top: auto;
  margin-top: calc(-7px + 10%) !important;
  align-self: auto;
  padding: 0px;
}
.text__tNvOf {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: #000000;
  font-size: 18px;
  margin-top: 17px;
  margin-left: 10px;
}
@media (max-width: 1440px) {
  .text__tNvOf {
    font-size: 1.25vw;
    margin-top: 1.2vw;
    margin-left: 0.6vw;
  }
}
.reminderNumber {
  position: relative;
  width: 46px;
  height: 35px;
  max-width: 100%;
  left: auto;
  top: auto;
  text-align: center;
  padding-left: 2px;
  font-size: 18px;
  margin-top: 14px;
  margin-left: 15px;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .reminderNumber {
    width: 3vw;
    height: 2.4vw;
    font-size: 1.25vw;
    flex-shrink: 0;
  }
}
.paymentReminderSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 15px;
  margin-top: 11px;
}
.option___8RAn9:global(.__wab_instance) {
  position: relative;
}
.text__udBzH {
  padding-bottom: 0px;
}
.option___1Bu53:global(.__wab_instance) {
  position: relative;
}
.option__pYqsG:global(.__wab_instance) {
  position: relative;
}
.paymentReminderStack2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 295px;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background: #44c0ff00;
  left: auto;
  top: auto;
  margin-top: calc(-25px + 10%) !important;
  align-self: auto;
}
.title29 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 2;
  color: #000000;
  width: 100%;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0px;
  margin-bottom: 0%;
  padding-right: 0px;
  text-align: left;
  max-width: 258px;
  margin-left: 10px;
  padding-left: 0px;
  min-width: 0;
}
@media (max-width: 1440px) {
  .title29 {
    font-size: 1.2vw;
    left: auto;
    top: auto;
    margin-left: 0.7vw;
  }
}
.errorText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  font-weight: 500;
  font-size: 16px;
  margin-top: 50px;
}
@media (max-width: 1440px) {
  .errorText {
    margin-top: 3.15vw;
    margin-bottom: 0vw;
    font-size: 1.1vw;
  }
}
.sendButton {
  width: 216px;
  height: 54px;
  background: #3555ff;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 120px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .sendButton {
    width: 23%;
    height: 2.4%;
    justify-content: center;
    align-items: center;
    margin-top: 3.5vw;
  }
}
.root .sendButton:hover {
  background: #3555ffd4;
}
.root .sendButton:active {
  background: #3555ffd4;
  border-width: 1px;
  border-style: solid;
}
.text__csTA {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .text__csTA {
    font-size: 1vw;
  }
}
