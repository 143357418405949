.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-top-color: #d3d3d3e6;
  border-top-style: solid;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 5px 0px;
  border-width: 0px;
}
.submitButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 48px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  align-self: center;
  margin-top: 0px;
  margin-right: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1600px) {
  .submitButton {
    background: #3555ff;
  }
}
@media (max-width: 1440px) {
  .submitButton {
    width: 12.14vw;
    height: 3.3vw;
    margin-right: 3.2vw;
    flex-shrink: 0;
  }
}
.root .submitButton:hover {
  background: #3555ffd4;
}
.root .submitButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 0px;
}
@media (max-width: 1440px) {
  .text {
    font-size: 1.25vw;
  }
}
