.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
.freeBox__btElp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 8px 0px 8px 8px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 20%;
  align-self: auto;
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: 12%;
  flex-direction: row;
  min-width: 0;
  margin: 2.2% 1% 1%;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
.title {
  display: block;
  font-size: 1.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
}
.addPaymentButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: calc(38% + 0%) !important;
  flex-shrink: 0;
}
.searchVertStack {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3d9;
  background: #ffffff;
  width: 9.5vw;
  height: 2.3vw;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  margin-left: calc(3% + 0%) !important;
  justify-content: flex-start;
  margin-top: 0%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .searchVertStack {
    width: 11vw;
    height: 2.3vw;
    margin-left: calc(3% + 0%) !important;
    flex-shrink: 0;
  }
}
.freeBox___653MK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  height: 100%;
  max-width: none;
  background: #44c0ff00;
  margin-top: 2%;
  min-height: 0;
  padding: 0% 0px 0px;
}
.figmaPaste3 {
  display: flex;
  position: relative;
  width: 100%;
  height: 7.2%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin: 0% 0px 0px;
}
.text__yBlYt {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 5.1%;
}
.text__ol7Zj {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13.4%;
}
.text__o3Jb {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 11.9%;
}
.text___2PPq {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 14%;
}
.text__k4HPj {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.paymentsListCard:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: 2%;
  flex-shrink: 0;
}
