.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
.freeBox__y852M {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 16.5%;
  align-self: auto;
}
.figmaPaste {
  display: block;
  position: relative;
  width: 85%;
  height: 18%;
  margin-top: 0.5%;
  border-bottom: 1px solid #b0aeae;
}
.text___9Z79L {
  display: block;
  font-size: 1.9vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  left: 5.49%;
  top: 52.17%;
}
.text__hNZp8 {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  left: 5.42%;
  top: 10.87%;
}
.rectangle54 {
  background: rgba(123, 198, 96, 0.709);
  width: 2.9vw;
  height: 1.6vw;
  display: flex;
  position: absolute;
  left: 26.26%;
  top: 55.43%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.text__o2CVl {
  display: block;
  font-size: 0.75vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(29, 112, 0, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
}
.rectangle57 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.3vw;
  height: 2.1vw;
  display: flex;
  position: absolute;
  left: auto;
  top: 60.2%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0%;
  border-radius: 10px;
}
.text__mcoBs {
  display: block;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
.rectangle58 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(128, 128, 128, 1);
  width: 7.3vw;
  height: 2.1vw;
  display: flex;
  position: absolute;
  left: auto;
  top: 19.9%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0%;
  border-radius: 10px;
}
.text__mmcQ8 {
  display: block;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
.freeBox__rWLfB {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 12.4%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
}
.freeBox__u4Yoh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__nIm3Y {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(98, 98, 98, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.text__zjqmz {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
  margin-top: 7%;
}
.freeBox___1ZApo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1%;
  height: 80%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
  border-left: 1px solid #b0aeae;
}
.freeBox___5AoZq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__eSvgV {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(98, 98, 98, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.text__nRlGb {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
  margin-top: 7%;
}
.freeBox__gIc5I {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1%;
  height: 80%;
  max-width: 100%;
  background: #44c0ff00;
  padding: 0px;
  border-left: 1px solid #b0aeae;
}
.freeBox__bd9HL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text___0HPdn {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(98, 98, 98, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
}
.text__yLnEq {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 20%;
  margin-top: 7%;
}
.title2 {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(69, 94, 245, 1);
  width: auto;
  height: auto;
  position: absolute;
  left: 84.38%;
  top: 57.81%;
}
.text__inOVa {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  left: 13.78%;
  top: 58.69%;
  height: auto;
}
.title {
  display: block;
  font-size: 1.4vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: absolute;
  left: 50.83%;
  top: 56.93%;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #b0aeae;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 15vw;
  height: 27px;
  display: block;
  transform-origin: top left;
  left: 12.35%;
  top: 62.5%;
  flex-shrink: 0;
}
.text__fjmpy {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3b3b3b;
  width: auto;
  height: auto;
  position: absolute;
  left: 14.01%;
  top: 85.16%;
}
.text__tLGp {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  position: absolute;
  left: 13.72%;
  top: 66.8%;
}
.text__dh6NO {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3b3b3b;
  width: auto;
  height: auto;
  position: absolute;
  left: 13.78%;
  top: 73.34%;
}
.text___8FiFu {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  position: absolute;
  left: 13.84%;
  top: 79.2%;
}
.rectangle50 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(177, 177, 177, 1);
  width: 33vw;
  height: 14vw;
  display: block;
  position: absolute;
  left: 50.48%;
  top: 62.89%;
  flex-shrink: 0;
  border-radius: 5px;
}
