.root {
  box-shadow: 0px 4px 4px 0px #0000004d;
  background: rgba(81, 0, 255, 1);
  width: 370px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border-width: 0px;
}
@media (max-width: 1370px) {
  .root {
    width: 315px;
    height: 41px;
  }
}
.root:hover {
  background: #6118fd;
}
.root:active {
  background: #4a00e8;
  border: 1px solid #757575;
}
.text {
  display: block;
  font-size: 19px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  transform: translate(0.41900000000001114px, -0.28899999999998727px);
}
@media (max-width: 1370px) {
  .text {
    font-size: 17px;
  }
}
