.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
.freeBox__e04G2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 0px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 16.5%;
  align-self: auto;
  max-height: 150px;
  margin-bottom: 0%;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox___5Yvv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: 7%;
  margin-top: 0px;
  border-right-width: 1px;
  border-right-style: solid;
  margin-bottom: 0.5%;
  overflow: visible;
  min-height: 0;
  padding: 0px;
}
.title2 {
  display: block;
  font-size: 1.15vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #474747;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 10.5% 0% 0%;
}
.title {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 7%;
  flex-direction: row;
}
.slotTargetContractName {
  font-family: "Open Sans", sans-serif;
  font-size: 1.35vw;
  font-weight: 700;
  color: #000000;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.title8 {
  display: block;
  font-size: 1.15vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #333333;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 15% 0% 0%;
}
.clientNameStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 6.5%;
  cursor: pointer;
  padding: 0px;
}
.clientName {
  display: block;
  font-size: 1.35vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  margin: 0%;
}
.title10 {
  display: block;
  font-size: 1.15vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #333333;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 13.5% 0% 0%;
}
.budgetAmount {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  margin: 6.5% 0% 0%;
}
.slotTargetBudgetAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 700;
  color: #000000;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.title14 {
  display: block;
  font-size: 1.15vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #333333;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 13.5% 0% 0%;
}
.amountPaidText {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  margin: 6.5% 0% 0%;
}
.slotTargetAmountPaid {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 700;
  color: #000000;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.contractTerms {
  display: block;
  font-size: 1.15vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #333333;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 17% 0% 0%;
}
.downloadContract {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  left: auto;
  top: auto;
  margin-left: 10%;
  margin-top: 8%;
  cursor: pointer;
  padding: 0px;
}
.svg___7V7Xa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(0.40000000000000036px, -0.08099999999999996px);
  width: 4vw;
  height: 5vw;
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.freeBox__on8R {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1.6vw;
  height: 1.7vw;
  max-width: 100%;
  background: #ffffff;
  left: 68.83%;
  top: 76.04%;
  flex-shrink: 0;
  border-radius: 1000px;
  padding: 0px;
  border-width: 1px;
  border-style: solid;
}
.svg__oCSwA {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: 1em;
  flex-shrink: 0;
}
.freeBox__zl54J {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__mdOm3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 3px;
  max-width: 100%;
  margin-bottom: -3%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.inProgressStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0px;
}
.title6 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 0%;
  margin-top: 1%;
}
.freeBox__jpP2G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 0px;
  margin: 6% 0% 0%;
}
.title17 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 8.5%;
}
.title18 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 17.5%;
}
.title19 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 16%;
}
.title20 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 12.5%;
}
.inProgressCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 3.5%;
  min-width: 0;
  padding: 0px;
}
.inProgressCards > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.inProgressCards > :global(.__wab_flex-container) > *,
.inProgressCards > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.inProgressCards > :global(.__wab_flex-container) > picture > img,
.inProgressCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.contractInstanceCard__bl1S6:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.contractInstanceCard__mh5Za:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.awaitingApprovalStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0px;
}
.title7 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 0%;
  padding-bottom: 0px;
}
.freeBox__jGHfQ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 0px;
  margin: 6% 0% 0%;
}
.title21 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 8.5%;
}
.title22 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 17.5%;
}
.title23 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 16%;
}
.title24 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 12.5%;
}
.awaitingApprovalCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 3.5%;
  min-width: 0;
  padding: 0px;
}
.awaitingApprovalCards > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.awaitingApprovalCards > :global(.__wab_flex-container) > *,
.awaitingApprovalCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.awaitingApprovalCards > :global(.__wab_flex-container) > picture > img,
.awaitingApprovalCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.contractInstanceCard__pzsxg:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.awaitingEscrowStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0px;
}
.title9 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 0%;
}
.freeBox__sFds2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 0px;
  margin: 6% 0% 0%;
}
.title25 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 8.5%;
}
.title26 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 17.5%;
}
.title27 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 16%;
}
.title28 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 12.5%;
}
.awaitingEscrowCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 3.5%;
  min-width: 0;
  padding: 0px;
}
.awaitingEscrowCards > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.awaitingEscrowCards > :global(.__wab_flex-container) > *,
.awaitingEscrowCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.awaitingEscrowCards > :global(.__wab_flex-container) > picture > img,
.awaitingEscrowCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.contractInstanceCard__uxo3J:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.awaitingPaymentStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0px;
}
.title13 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 0%;
  padding-bottom: 0px;
}
.freeBox___8Ol33 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 0px;
  margin: 6% 0% 0%;
}
.title34 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 8.5%;
}
.title35 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 17.5%;
}
.title36 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 16%;
}
.title37 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 12.5%;
}
.awaitingPaymentCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 3.5%;
  min-width: 0;
  padding: 0px;
}
.awaitingPaymentCards > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.awaitingPaymentCards > :global(.__wab_flex-container) > *,
.awaitingPaymentCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.awaitingPaymentCards > :global(.__wab_flex-container) > picture > img,
.awaitingPaymentCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.contractInstanceCard__aXfWs:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.text__z5HY {
  height: auto;
  text-align: center;
}
.upcomingMilestonesStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0px;
}
.freeBox__w9Z9B {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-left: 0%;
  min-width: 0;
  padding: 0px;
}
.title15 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 2;
  color: rgba(36, 38, 51, 1);
  padding-bottom: 0px;
  width: auto;
  height: auto;
  text-align: center;
}
.requestPaymentButton {
  background: #3555ff;
  width: 11vw;
  height: 2.1vw;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 16.3vw;
  align-self: auto;
  flex-shrink: 0;
  border-radius: 10px;
  border-width: 0px;
}
.requestPaymentButton > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 6%);
  width: calc(100% + 6%);
}
.requestPaymentButton > :global(.__wab_flex-container) > *,
.requestPaymentButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.requestPaymentButton > :global(.__wab_flex-container) > picture > img,
.requestPaymentButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6%;
}
@media (max-width: 1600px) {
  .requestPaymentButton {
    margin-left: 15.8vw;
  }
}
@media (max-width: 1440px) {
  .requestPaymentButton {
    margin-left: 15.5vw;
  }
}
.root .requestPaymentButton:hover {
  background: #3555ffd4;
}
.root .requestPaymentButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__vCtOy {
  display: block;
  font-size: 0.78vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
.freeBox__glxFq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 0px;
  margin: 6% 0% 0%;
}
.title38 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 8.5%;
}
.title39 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 17.5%;
}
.title40 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 16%;
}
.title41 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 12.5%;
}
.upcomingMilestonesCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 3.5%;
  min-width: 0;
  padding: 0px;
}
.upcomingMilestonesCards > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.upcomingMilestonesCards > :global(.__wab_flex-container) > *,
.upcomingMilestonesCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.upcomingMilestonesCards > :global(.__wab_flex-container) > picture > img,
.upcomingMilestonesCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.contractInstanceCard__skPby:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.text__okTL {
  height: auto;
}
.disputesStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0px;
}
.title11 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 0%;
}
.freeBox__df3GI {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  padding: 0px;
  margin: 6% 0% 0%;
}
.title29 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 8.5%;
}
.title31 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 17.5%;
}
.title32 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 16%;
}
.title33 {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0% 0% 0% 12.5%;
}
.disputesCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 3.5%;
  min-width: 0;
  padding: 0px;
}
.disputesCards > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.disputesCards > :global(.__wab_flex-container) > *,
.disputesCards > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.disputesCards > :global(.__wab_flex-container) > picture > img,
.disputesCards
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.contractInstanceCard__oTYll:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  flex-shrink: 0;
}
.milestoneTitle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-top: 5%;
  margin-bottom: 4%;
  overflow: visible;
  min-width: 0;
  padding: 0px;
}
.title30 {
  display: block;
  font-size: 1.3vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 10%;
  margin-top: 0px;
}
.addMilestoneButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 35%;
  flex-shrink: 0;
}
.milestoneStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 70%;
  height: auto;
  max-width: 100%;
  margin-left: 10%;
  margin-bottom: 0px;
  overflow: visible;
  padding: 0px 0px 50px;
}
.milestoneStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.milestoneStack > :global(.__wab_flex-container) > *,
.milestoneStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.milestoneStack > :global(.__wab_flex-container) > picture > img,
.milestoneStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.milestoneInstance__kobAx:global(.__wab_instance) {
  position: relative;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  height: auto;
}
.milestoneInstance__yLMgH:global(.__wab_instance) {
  position: relative;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  height: auto;
}
.milestoneInstance__wIuHm:global(.__wab_instance) {
  position: relative;
  margin-top: calc(0% + 20px) !important;
  margin-left: 0%;
  height: auto;
}
