.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
.freeBox__gHhqm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  justify-self: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 8px;
}
.mainStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__kzv8J {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.textStack {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.slotTargetDisplayText {
  font-weight: 600;
  font-size: var(--token-B10hUni6jzpI);
}
