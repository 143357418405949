.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(#ffffff, #ffffff), url("./images/dashboardLoginDarkTheme1Svg.svg"),
    linear-gradient(#ffffff, #ffffff), #ffffff;
  min-width: 0;
  min-height: 0;
}
.signupScreen {
  background: rgba(66, 0, 255, 0.939);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.frame1 {
  background: rgba(255, 255, 255, 1);
  width: 540px;
  height: 700px;
  display: flex;
  overflow: hidden;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  margin-bottom: 3%;
  flex-direction: column;
  box-shadow: 4px 4px 16px 4px #00000033;
  margin-top: 2%;
  flex-shrink: 0;
  border-radius: 5px;
}
.frame1 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 38px);
  height: calc(100% + 38px);
}
.frame1 > :global(.__wab_flex-container) > *,
.frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1 > :global(.__wab_flex-container) > picture > img,
.frame1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 38px;
}
@media (max-width: 1440px) {
  .frame1 {
    margin-bottom: 5.4%;
  }
}
@media (max-width: 1370px) {
  .frame1 {
    margin-bottom: 5%;
    width: 460px;
    height: 581px;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 28px);
    height: calc(100% + 28px);
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) > *,
  .frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frame1 > :global(.__wab_flex-container) > picture > img,
  .frame1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 28px;
  }
}
.text__zQa6B {
  display: block;
  font-size: 28px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 100%;
  color: #181818;
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(38px + 38px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .text__zQa6B {
    margin-top: calc(38px + 38px) !important;
  }
}
@media (max-width: 1370px) {
  .text__zQa6B {
    font-size: 24px;
    margin-top: calc(33px + 28px) !important;
    margin-left: calc(0px + 0px) !important;
  }
}
.username {
  width: 100%;
  height: 96px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-right: 40px;
  margin-top: calc(-6px + 38px) !important;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .username {
    margin-top: calc(-6px + 38px) !important;
  }
}
@media (max-width: 1370px) {
  .username {
    margin-right: 70px;
    height: 81.6px;
    margin-top: calc(-4px + 28px) !important;
    flex-shrink: 0;
  }
}
.rectangle {
  background: rgba(233, 233, 233, 0);
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  width: 370px;
  height: 48px;
  display: flex;
  position: absolute;
  top: auto;
  left: 109px;
  bottom: 1px;
  flex-direction: column;
  border-radius: 10px;
}
@media (max-width: 1370px) {
  .rectangle {
    width: 315px;
    height: 41px;
  }
}
.signupEmailInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__dkE4 {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(60, 60, 60, 1);
  position: absolute;
  top: 5px;
  left: 120px;
}
@media (max-width: 1370px) {
  .text__dkE4 {
    font-size: 14px;
  }
}
.invalidEmailText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-bottom: -20px;
  margin-top: calc(-25px + 38px) !important;
  align-self: flex-start;
  margin-left: 98px;
  font-size: 15px;
  left: auto;
  top: auto;
}
@media (max-width: 1440px) {
  .invalidEmailText {
    margin-top: calc(-25px + 38px) !important;
  }
}
@media (max-width: 1370px) {
  .invalidEmailText {
    font-size: 13.5px;
    margin-left: calc(80px + 0px) !important;
    margin-top: calc(-15px + 28px) !important;
    left: auto;
    top: auto;
  }
}
.username3 {
  width: 100%;
  height: 96px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-right: 40px;
  margin-top: calc(0px + 38px) !important;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .username3 {
    margin-top: calc(0px + 38px) !important;
  }
}
@media (max-width: 1370px) {
  .username3 {
    height: 81.6px;
    margin-right: 70px;
    margin-top: calc(0px + 28px) !important;
    flex-shrink: 0;
  }
}
.rectangle4 {
  background: rgba(233, 233, 233, 0);
  width: 370px;
  height: 48px;
  display: flex;
  position: absolute;
  top: auto;
  left: 109px;
  bottom: 1px;
  flex-direction: column;
}
@media (max-width: 1370px) {
  .rectangle4 {
    width: 315px;
    height: 41px;
  }
}
.signupPasswordInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__rkfHc {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(60, 60, 60, 1);
  position: absolute;
  top: 5px;
  left: 120px;
}
@media (max-width: 1370px) {
  .text__rkfHc {
    font-size: 14px;
  }
}
.username4 {
  width: 100%;
  height: 96px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-right: 40px;
  margin-top: calc(0px + 38px) !important;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 1370px) {
  .username4 {
    height: 81.6px;
    margin-right: 70px;
    left: auto;
    top: auto;
    margin-top: calc(0px + 28px) !important;
    flex-shrink: 0;
  }
}
.rectangle5 {
  background: rgba(233, 233, 233, 0);
  width: 370px;
  height: 48px;
  display: flex;
  position: absolute;
  top: auto;
  left: 109px;
  bottom: 1px;
  flex-direction: column;
}
@media (max-width: 1370px) {
  .rectangle5 {
    width: 315px;
    height: 41px;
  }
}
.signupConfirmPasswordInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ibWEs {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(60, 60, 60, 1);
  position: absolute;
  top: 5px;
  left: 120px;
  height: auto;
}
@media (max-width: 1370px) {
  .text__ibWEs {
    font-size: 14px;
  }
}
.incorrectPasswordsText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-bottom: -20px;
  margin-top: calc(-25px + 38px) !important;
  align-self: flex-start;
  margin-left: 98px;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .incorrectPasswordsText {
    margin-top: calc(-25px + 38px) !important;
  }
}
@media (max-width: 1370px) {
  .incorrectPasswordsText {
    font-size: 13.5px;
    margin-left: calc(80px + 0px) !important;
    margin-top: calc(-15px + 28px) !important;
  }
}
.customErrorInstance:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
  margin-top: calc(-25px + 38px) !important;
  margin-bottom: -20px;
  margin-left: 98px;
  width: 351px;
}
@media (max-width: 1440px) {
  .customErrorInstance:global(.__wab_instance) {
    margin-top: calc(-25px + 38px) !important;
  }
}
@media (max-width: 1370px) {
  .customErrorInstance:global(.__wab_instance) {
    margin-top: calc(-15px + 28px) !important;
    margin-left: calc(80px + 0px) !important;
    width: 302px;
  }
}
.text___6MyOk {
  color: #ff0000;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.checkAgree:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 20px;
  margin-left: 15%;
  margin-top: 0.5%;
}
.text___43ADe {
  position: relative;
  width: 351px;
  height: auto;
  max-width: 800px;
  font-size: 13.5px;
  color: #000000;
  margin-left: 1.2%;
  margin-top: 0%;
  letter-spacing: normal;
  line-height: 150%;
  flex-shrink: 0;
}
@media (max-width: 1370px) {
  .text___43ADe {
    font-size: 12px;
    width: 302px;
    flex-shrink: 0;
  }
}
.link__ynXmk {
  text-decoration-line: none;
}
.link__kt9Kw {
  text-decoration-line: none;
}
.link___1QeyM {
  text-decoration-line: none;
}
.link__mOd0 {
  text-decoration-line: none;
}
.signupButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: calc(0px + 38px) !important;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .signupButton:global(.__wab_instance) {
    margin-top: calc(0px + 38px) !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .signupButton:global(.__wab_instance) {
    margin-top: calc(0px + 28px) !important;
    flex-shrink: 0;
  }
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block !important;
  top: auto;
  left: auto;
}
.img > picture > img {
  object-fit: cover;
}
