.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__wc6W {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__zXyQ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__w8G3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__vK1Sq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__qQaas {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__yjwCp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__ypqQg {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__tXcWx {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__bILx7 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__bZiE {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___8Qba8 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__mL7E {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__bsXxw {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__oGy7S {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__u8Mx6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__u8Mx6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__u8Mx6 > :global(.__wab_flex-container) > *,
.freeBox__u8Mx6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__u8Mx6 > :global(.__wab_flex-container) > picture > img,
.freeBox__u8Mx6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__uieMy {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__qjWyO {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__jfpfn {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text___8WeTz {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox___2DZjk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1380px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__aVwIv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 40px 0px 20px;
}
.freeBox__ejLVx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__w8OiI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text__wgvb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__rTzCr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__eNcxG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__baMH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__qwkhn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.legalBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__ir66A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__yxh6U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.displayBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__e2XxJ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__geqIq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.textInput {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__w39Ot:global(.__wab_instance) {
  position: relative;
}
.option__bJoU7:global(.__wab_instance) {
  position: relative;
}
.option__qVvzs:global(.__wab_instance) {
  position: relative;
}
.option__ermqb:global(.__wab_instance) {
  position: relative;
}
.option__gl6Xa:global(.__wab_instance) {
  position: relative;
}
.option___0ScHi:global(.__wab_instance) {
  position: relative;
}
.option__xyeJh:global(.__wab_instance) {
  position: relative;
}
.option__buJwY:global(.__wab_instance) {
  position: relative;
}
.option___8Vp6:global(.__wab_instance) {
  position: relative;
}
.option__s75R:global(.__wab_instance) {
  position: relative;
}
.option__isOW:global(.__wab_instance) {
  position: relative;
}
.option__jxSxz:global(.__wab_instance) {
  position: relative;
}
.option__vpTwv:global(.__wab_instance) {
  position: relative;
}
.option___43JQ:global(.__wab_instance) {
  position: relative;
}
.option__x8Shr:global(.__wab_instance) {
  position: relative;
}
.option__otMB:global(.__wab_instance) {
  position: relative;
}
.option___2GZcM:global(.__wab_instance) {
  position: relative;
}
.option__vLs5S:global(.__wab_instance) {
  position: relative;
}
.option__qJp9E:global(.__wab_instance) {
  position: relative;
}
.option__yMkG:global(.__wab_instance) {
  position: relative;
}
.option__wB750:global(.__wab_instance) {
  position: relative;
}
.option__qInp6:global(.__wab_instance) {
  position: relative;
}
.option__seppp:global(.__wab_instance) {
  position: relative;
}
.option___7Scmz:global(.__wab_instance) {
  position: relative;
}
.option__ajIjo:global(.__wab_instance) {
  position: relative;
}
.option__js2Ot:global(.__wab_instance) {
  position: relative;
}
.option__r39In:global(.__wab_instance) {
  position: relative;
}
.option__hLOr:global(.__wab_instance) {
  position: relative;
}
.option__fH2Uh:global(.__wab_instance) {
  position: relative;
}
.option__hCKxb:global(.__wab_instance) {
  position: relative;
}
.option__z1M0Y:global(.__wab_instance) {
  position: relative;
}
.option___9BNdQ:global(.__wab_instance) {
  position: relative;
}
.option__enJso:global(.__wab_instance) {
  position: relative;
}
.option___8Poi:global(.__wab_instance) {
  position: relative;
}
.option__nMrl5:global(.__wab_instance) {
  position: relative;
}
.option__gqtCx:global(.__wab_instance) {
  position: relative;
}
.option__pnjfB:global(.__wab_instance) {
  position: relative;
}
.option__d1Eps:global(.__wab_instance) {
  position: relative;
}
.option__zHNyI:global(.__wab_instance) {
  position: relative;
}
.option___6NW2R:global(.__wab_instance) {
  position: relative;
}
.option__f0Gjq:global(.__wab_instance) {
  position: relative;
}
.option__yD90:global(.__wab_instance) {
  position: relative;
}
.option___0O63:global(.__wab_instance) {
  position: relative;
}
.option__t0Nvd:global(.__wab_instance) {
  position: relative;
}
.option___9JR5V:global(.__wab_instance) {
  position: relative;
}
.option__b6Ltu:global(.__wab_instance) {
  position: relative;
}
.option__kEn44:global(.__wab_instance) {
  position: relative;
}
.option__jilEi:global(.__wab_instance) {
  position: relative;
}
.option__uoBU:global(.__wab_instance) {
  position: relative;
}
.option__oTUI:global(.__wab_instance) {
  position: relative;
}
.option__kMbPo:global(.__wab_instance) {
  position: relative;
}
.option__rVjIh:global(.__wab_instance) {
  position: relative;
}
.option__oeDtx:global(.__wab_instance) {
  position: relative;
}
.option__hVlb7:global(.__wab_instance) {
  position: relative;
}
.option___1Lrq3:global(.__wab_instance) {
  position: relative;
}
.option__ch0B1:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__zuzCx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__aa1P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectIndustry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-left: 0px;
  flex-shrink: 0;
}
.option__ejPaP:global(.__wab_instance) {
  position: relative;
}
.option__tIEf:global(.__wab_instance) {
  position: relative;
}
.option___49WZt:global(.__wab_instance) {
  position: relative;
}
.option__maO6H:global(.__wab_instance) {
  position: relative;
}
.option__t7N51:global(.__wab_instance) {
  position: relative;
}
.option__lpvbX:global(.__wab_instance) {
  position: relative;
}
.option__xhYhU:global(.__wab_instance) {
  position: relative;
}
.option__cw85W:global(.__wab_instance) {
  position: relative;
}
.option__lsa1M:global(.__wab_instance) {
  position: relative;
}
.option__bhGuY:global(.__wab_instance) {
  position: relative;
}
.option__chSnm:global(.__wab_instance) {
  position: relative;
}
.option__zrs3Q:global(.__wab_instance) {
  position: relative;
}
.option__imjnF:global(.__wab_instance) {
  position: relative;
}
.option__imUen:global(.__wab_instance) {
  position: relative;
}
.option__d9Bn:global(.__wab_instance) {
  position: relative;
}
.option___0BheJ:global(.__wab_instance) {
  position: relative;
}
.option__pGiYw:global(.__wab_instance) {
  position: relative;
}
.option__pgM15:global(.__wab_instance) {
  position: relative;
}
.option__jwm62:global(.__wab_instance) {
  position: relative;
}
.option__hadMd:global(.__wab_instance) {
  position: relative;
}
.option___0Sr2I:global(.__wab_instance) {
  position: relative;
}
.option___1T6Sg:global(.__wab_instance) {
  position: relative;
}
.option__tKqmA:global(.__wab_instance) {
  position: relative;
}
.option__jDVw:global(.__wab_instance) {
  position: relative;
}
.option__t5Zd:global(.__wab_instance) {
  position: relative;
}
.freeBox__iJcFl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__obLgH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.freeBox__mbSc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__p16Dv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.continueButton:global(.__wab_instance) {
  position: relative;
  margin-top: 51px;
  flex-shrink: 0;
}
