.root {
  background: rgba(123, 198, 96, 0.709);
  width: 6.5vw;
  height: 1.7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100px;
}
.text {
  display: block;
  font-size: 0.75vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(29, 112, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
