.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
.profileButton {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #383434;
  transform: translate(0px, 0.001px);
  width: 100%;
  height: 1.4vw;
  display: block;
  top: auto;
  left: auto;
  align-self: center;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}
.profileButtonpostClick {
  margin-bottom: 0vw;
  color: #383434;
}
.root:hover .profileButton {
  color: #595757;
}
.signOutButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: 3vw;
}
.signOutButtonpostClick:global(.__wab_instance) {
  position: absolute;
  top: 0.3vw;
  left: -9vw;
  margin-top: 1.3vw;
}
.freeBox {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.5vw;
  height: 1.4vw;
  max-width: 100%;
  background: #2b2b2b;
  margin-top: 0.3vw;
  flex-shrink: 0;
  display: none;
  border-radius: 5px;
  padding: 0px;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
}
