.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: #158fe8;
  max-width: 88px;
  min-width: 0;
  border-radius: 8px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  color: #ffffff;
  min-width: 0;
}
