.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox___3Sj3V {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text___2Gwl0 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  text-align: center;
}
.freeBox__jjhrb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__mdTmw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: none;
  margin-top: 30px;
  min-width: 0;
  padding: 0px;
}
@media (max-width: 414px) {
  .freeBox__mdTmw {
    width: 330px;
    flex-shrink: 0;
  }
}
.text__hD8J5 {
  display: block;
  font-size: var(--token-BlA-iDkaAWvO);
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: #242424;
  position: relative;
  top: auto;
  left: auto;
  margin-left: 7px;
}
@media (max-width: 1370px) {
  .text__hD8J5 {
    font-size: 14px;
    left: auto;
    top: auto;
  }
}
@media (max-width: 414px) {
  .text__hD8J5 {
    font-size: 13.7px;
  }
}
.signupPasswordInput:global(.__wab_instance) {
  margin-top: 21px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 414px) {
  .signupPasswordInput:global(.__wab_instance) {
    width: 330px;
    flex-shrink: 0;
  }
}
.customErrorInstance:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 17px;
  margin-right: 0px;
  margin-left: 11px;
}
.text___4PuaB {
  color: #ff0000;
  font-size: 15px;
  line-height: 1.6;
}
@media (max-width: 414px) {
  .text___4PuaB {
    font-size: 13.7px;
  }
}
.freeBox__qkivu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.saveBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 150px;
  height: 43px;
  flex-shrink: 0;
}
.svg__mni8B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1WVw1 {
  padding-top: 0px;
  margin-top: 0px;
}
.svg__tgEo7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
