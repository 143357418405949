.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(185, 185, 185, 1);
  width: 100%;
  height: 3.3vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-width: 0;
}
.freeBox__mkuFh {
  display: flex;
  width: 10%;
  position: relative;
  left: auto;
  top: auto;
  justify-content: center;
  flex-direction: row;
  margin: 0% 0% 0% 8%;
}
.slotTargetAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  color: #000000;
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__njHf {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: 8.9vw;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  margin: 0% 0% 0% 9.5%;
}
.slotTargetStatus {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox___37Czj {
  display: flex;
  width: 5%;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 12%;
  justify-content: center;
  flex-direction: row;
}
.slotTargetMilestoneNumber {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  color: #000000;
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.freeBox__tdWMw {
  display: flex;
  width: 10%;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  margin: 0% 0% 0% 15%;
}
.slotTargetDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  color: #000000;
  text-align: left;
  line-height: 130%;
  letter-spacing: 0.3px;
}
