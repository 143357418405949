.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 4px 0px 8px 8px;
}
.topBar__ds2H:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.topBar__oJkq5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 27%;
  align-self: auto;
}
.sheet {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 80%;
  height: 90%;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  margin-top: 3.2%;
  align-self: center;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  max-height: 401px;
  margin-bottom: 1%;
  overflow: visible;
  border-radius: 8px;
}
.graphParentStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: none;
  background: #44c0ff00;
  margin-left: 20px;
  margin-top: 0%;
  margin-right: 20px;
  min-width: 0;
  min-height: 0;
  padding: 0px 70px;
}
@media (max-width: 1370px) {
  .graphParentStack {
    padding-bottom: 0px;
  }
}
.freeBox___5Q1Ve {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 2.5%;
  margin-left: 0%;
  align-self: auto;
  min-width: 0;
  padding: 8px;
}
.freeBox__cg468 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: center;
  padding: 0px;
}
.amountStack {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4%;
  margin-top: 20px;
  justify-content: center;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .amountStack {
    left: auto;
    top: auto;
  }
}
.slotTargetTotalBalance {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
@media (max-width: 1440px) {
  .slotTargetTotalBalance {
    font-size: 1.3vw;
  }
}
.freeBox__aqhZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: 100%;
  margin-left: 653px;
  min-height: 0;
  padding: 0px;
  border-bottom: 0px solid #dedfea;
  border-left: 0px solid #dedfea;
}
.text__aDmr2 {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin: 0vw 0% 1.5vw 0vw;
}
@media (max-width: 1440px) {
  .text__aDmr2 {
    font-size: 1.16vw;
    margin-top: 21%;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .text__aDmr2 {
    margin-top: 19%;
    left: auto;
    top: auto;
  }
}
.freeBox___03Ybo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0vw;
  padding: 0px;
}
.slotTargetAccountBalance {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
@media (max-width: 1440px) {
  .slotTargetAccountBalance {
    font-size: 1.3vw;
  }
}
.freeBox__fEc7U {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: 100%;
  margin-left: 72px;
  min-height: 0;
  padding: 0px;
  border-bottom: 0px solid #dedfea;
  border-left: 0px solid #dedfea;
}
.text__gUz8X {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin: 0vw 0% 1.5vw 0vw;
}
@media (max-width: 1440px) {
  .text__gUz8X {
    font-size: 1.16vw;
    margin-top: 21%;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .text__gUz8X {
    margin-top: 19%;
    left: auto;
    top: auto;
  }
}
.freeBox__gzfWj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0vw;
  padding: 0px;
}
.slotTargetPayouts {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
@media (max-width: 1440px) {
  .slotTargetPayouts {
    font-size: 1.3vw;
  }
}
.graphStack:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 52.2%;
  max-width: 1082px;
  min-width: 0;
  margin: 1% 0px 0%;
}
.graphLine {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
  margin: 0% 0% 0% 0.5%;
  border-top: 1px solid #dedfea;
}
.freeBox__r7Lyi {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  margin-left: 0px;
  left: 15px;
  top: 10px;
  display: flex;
  flex-direction: row;
}
.slotTargetStartDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7vw;
}
.freeBox___8XfVs {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-end;
  margin-left: 0%;
  left: auto;
  top: 10px;
  right: 15px;
  bottom: auto;
  display: flex;
  flex-direction: row;
}
.slotTargetEndDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7vw;
}
.freeBox___7XTWt {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 80%;
  height: 100%;
  max-width: 1200px;
  background: #44c0ff00;
  align-self: center;
  margin-top: 0px;
  min-height: 0;
  padding: 0px;
}
.freeBox___7XTWt > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox___7XTWt > :global(.__wab_flex-container) > *,
.freeBox___7XTWt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7XTWt > :global(.__wab_flex-container) > picture > img,
.freeBox___7XTWt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.freeBox__lbq2S {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 17.5vw;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #dedfea;
  min-height: auto;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__lbq2S {
    height: 21.5vw;
    min-height: auto;
  }
}
@media (max-width: 1370px) {
  .freeBox__lbq2S {
    height: 20.7vw;
  }
}
.freeBox___1N49G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4vw;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___1N49G {
    height: 5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .freeBox___1N49G {
    height: 4.8vw;
    flex-shrink: 0;
  }
}
.text__jeg9H {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 3vw;
}
.viewDetails {
  display: block;
  font-size: 0.85vw;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3555ff;
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-left: 0px;
  margin-left: 53%;
  margin-top: 0px;
  right: auto;
  cursor: pointer;
}
.paymentCardStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.dashboardPaymentsCard___8LeQa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .dashboardPaymentsCard___8LeQa:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .dashboardPaymentsCard___8LeQa:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.dashboardPaymentsCard__lbzY:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .dashboardPaymentsCard__lbzY:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .dashboardPaymentsCard__lbzY:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.dashboardPaymentsCard__bhhLe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .dashboardPaymentsCard__bhhLe:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .dashboardPaymentsCard__bhhLe:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__fg8Es {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 17.5vw;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #dedfea;
  min-height: auto;
  margin-left: calc(3vw + 0px) !important;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__fg8Es {
    height: 21.5vw;
    min-height: auto;
    margin-left: calc(3vw + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .freeBox__fg8Es {
    height: 20.7vw;
    margin-left: calc(3vw + 0px) !important;
  }
}
.freeBox__gelnS {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4vw;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__gelnS {
    height: 5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .freeBox__gelnS {
    height: 4.8vw;
    flex-shrink: 0;
  }
}
.text__xm00H {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 3vw;
}
.viewDetails2 {
  display: block;
  font-size: 0.85vw;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3555ff;
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-left: 0px;
  margin-left: 53%;
  margin-top: 0px;
  right: auto;
  cursor: pointer;
}
.contractCardStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.dashboardContractsCard__xSqg0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.dashboardContractsCard__kjpvy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.dashboardContractsCard__gfMhm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
