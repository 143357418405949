.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 4px 0px 8px 8px;
}
.topBar__rhwVs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.topBar__b3HIj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 27%;
  align-self: auto;
}
.sheet {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 80%;
  height: 90%;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  margin-top: 3.2%;
  align-self: center;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  max-height: 401px;
  margin-bottom: 1%;
  overflow: visible;
  border-radius: 8px;
}
.graphParentStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  height: 100%;
  max-width: none;
  background: #44c0ff00;
  margin-left: 20px;
  margin-top: 0%;
  min-height: 0;
  padding: 0px 0px 0px 20px;
}
@media (max-width: 1370px) {
  .graphParentStack {
    padding-bottom: 0px;
  }
}
.timeSelectStack:global(.__wab_instance) {
  position: relative;
  margin-top: 4.1%;
  margin-left: 3%;
}
.amountStack {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4%;
  margin-top: 2.5%;
  justify-content: center;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .amountStack {
    left: auto;
    top: auto;
  }
}
.slotTargetTotalBalance {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
@media (max-width: 1440px) {
  .slotTargetTotalBalance {
    font-size: 1.3vw;
  }
}
.graphStack:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 52.2%;
  max-width: 903px;
  min-width: 0;
  margin: 2% 1% 0% 0.5%;
}
.graphLine {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 902px;
  height: auto;
  max-width: 100%;
  padding: 0px;
  margin: 0% 0% 0% 0.5%;
  border-top: 1px solid #dedfea;
}
.freeBox__gZcnX {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  margin-left: 0px;
  left: 15px;
  top: 10px;
  display: flex;
  flex-direction: row;
}
.slotTargetStartDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7vw;
}
.freeBox__nzs3Z {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-end;
  margin-left: 0%;
  left: auto;
  top: 10px;
  right: 15px;
  bottom: auto;
  display: flex;
  flex-direction: row;
}
.slotTargetEndDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7vw;
}
.figmaPaste2 {
  display: flex;
  position: relative;
  width: 16.75%;
  height: 100%;
  left: auto;
  top: auto;
  align-self: center;
  flex-direction: column;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
  min-height: 0;
  border-left: 1px solid #dedfea;
}
.figmaPaste2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 0;
  margin-top: calc(0px - 8%);
  height: calc(100% + 8%);
}
.figmaPaste2 > :global(.__wab_flex-container) > *,
.figmaPaste2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste2 > :global(.__wab_flex-container) > picture > img,
.figmaPaste2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8%;
}
@media (max-width: 1440px) {
  .figmaPaste2 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.text__sQdJa {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: #242633;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin-top: calc(80px + 8%) !important;
  margin-right: 0%;
  margin-bottom: 0px;
  margin-left: 0%;
}
@media (max-width: 1440px) {
  .text__sQdJa {
    font-size: 1.16vw;
    margin-top: calc(21% + 8%) !important;
  }
}
@media (max-width: 1370px) {
  .text__sQdJa {
    margin-top: calc(19% + 8%) !important;
  }
}
.slotTargetAccountBalance {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
@media (max-width: 1440px) {
  .slotTargetAccountBalance {
    font-size: 1.3vw;
  }
}
.text__zdVxf {
  padding-right: 0px;
}
.text__e25Vk {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: #242633;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin-top: calc(60px + 8%) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .text__e25Vk {
    font-size: 1.16vw;
    margin-top: calc(20% + 8%) !important;
  }
}
@media (max-width: 1370px) {
  .text__e25Vk {
    margin-top: calc(18% + 8%) !important;
  }
}
.payouts {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  margin-bottom: 0px;
  justify-content: center;
  flex-direction: row;
}
.slotTargetPayouts {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
@media (max-width: 1440px) {
  .slotTargetPayouts {
    font-size: 1.3vw;
  }
}
.freeBox__cK1TP {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 80%;
  height: 100%;
  max-width: 1200px;
  background: #44c0ff00;
  align-self: center;
  margin-top: 0px;
  min-height: 0;
  padding: 0px;
}
.freeBox__cK1TP > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__cK1TP > :global(.__wab_flex-container) > *,
.freeBox__cK1TP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cK1TP > :global(.__wab_flex-container) > picture > img,
.freeBox__cK1TP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.freeBox__jxrde {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 17.5vw;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #dedfea;
  min-height: auto;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__jxrde {
    height: 21.5vw;
    min-height: auto;
  }
}
@media (max-width: 1370px) {
  .freeBox__jxrde {
    height: 20.7vw;
  }
}
.freeBox__e63Yu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4vw;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__e63Yu {
    height: 5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .freeBox__e63Yu {
    height: 4.8vw;
    flex-shrink: 0;
  }
}
.text__qsUob {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 3vw;
}
.viewDetails {
  display: block;
  font-size: 0.85vw;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3555ff;
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-left: 0px;
  margin-left: 53%;
  margin-top: 0px;
  right: auto;
  cursor: pointer;
}
.paymentCardStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.dashboardPaymentsCard__idYcT:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .dashboardPaymentsCard__idYcT:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .dashboardPaymentsCard__idYcT:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.dashboardPaymentsCard___0YuG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .dashboardPaymentsCard___0YuG:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .dashboardPaymentsCard___0YuG:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.dashboardPaymentsCard__sWUcs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1440px) {
  .dashboardPaymentsCard__sWUcs:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .dashboardPaymentsCard__sWUcs:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__vMg4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 17.5vw;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #dedfea;
  min-height: auto;
  margin-left: calc(3vw + 0px) !important;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__vMg4 {
    height: 21.5vw;
    min-height: auto;
    margin-left: calc(3vw + 0px) !important;
  }
}
@media (max-width: 1370px) {
  .freeBox__vMg4 {
    height: 20.7vw;
    margin-left: calc(3vw + 0px) !important;
  }
}
.freeBox__aIyyu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4vw;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__aIyyu {
    height: 5vw;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .freeBox__aIyyu {
    height: 4.8vw;
    flex-shrink: 0;
  }
}
.text___1IbCe {
  display: block;
  font-size: 1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 3vw;
}
.viewDetails2 {
  display: block;
  font-size: 0.85vw;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3555ff;
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-left: 0px;
  margin-left: 53%;
  margin-top: 0px;
  right: auto;
  cursor: pointer;
}
.contractCardStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.dashboardContractsCard__kmAJz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.dashboardContractsCard__lkrzc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.dashboardContractsCard__bAYdg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
