.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  align-content: center;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  min-width: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
.freeBox__qhiu0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: none;
  top: auto;
  left: auto;
  right: auto;
  margin-top: 0%;
  margin-bottom: 60px;
  justify-self: auto;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__qhiu0 {
    top: auto;
    left: auto;
    right: auto;
    margin-top: 10%;
  }
}
.approveButton {
  width: 216px;
  height: 54px;
  background: #02933a;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .approveButton {
    width: 216px;
    height: 54px;
    justify-content: center;
    align-items: center;
    background: #3555ff;
    flex-shrink: 0;
  }
}
.root .approveButton:hover {
  background: #02933ad4;
  left: auto;
  top: auto;
  right: auto;
}
.root .approveButton:active {
  background: #02933ad4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.text___528Z {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .text___528Z {
    font-size: 1.15vw;
  }
}
.declineButton {
  width: 216px;
  height: 54px;
  background: #d70019;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  flex-shrink: 0;
  border-radius: 100px;
}
@media (max-width: 1440px) {
  .declineButton {
    width: 216px;
    height: 54px;
    justify-content: center;
    align-items: center;
    background: #3555ff;
    flex-shrink: 0;
  }
}
.root .declineButton:hover {
  background: #d70019d4;
  left: auto;
  top: auto;
  right: auto;
}
.root .declineButton:active {
  background: #d70019d4;
  left: auto;
  top: auto;
  right: auto;
  border-width: 1px;
  border-style: solid;
}
.text__kPdHs {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .text__kPdHs {
    font-size: 1.15vw;
  }
}
.freeBox__zYksu {
  position: relative;
  width: 1000px;
  height: auto;
  max-width: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: auto;
  flex-shrink: 0;
}
.slotTargetTransactionData {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  line-height: 3;
  text-align: center;
}
