.input {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border:1px solid rgba(255, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #a3a3a3;
    padding-left: 10px;
    font-size: 15.4px;
    font: "Inter";
    color: #303030;
}