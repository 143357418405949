.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
.freeBox__o4V8X {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  min-height: 0;
  padding: 4px 0px 8px 8px;
}
.topBar__gpwZn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.topBar__uqOz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 27%;
  align-self: auto;
}
.sheet {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(222, 223, 234, 1);
  width: 80%;
  height: 90%;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  margin-top: 3.2%;
  align-self: center;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  max-height: 401px;
  margin-bottom: 1%;
  overflow: visible;
  border-radius: 8px;
}
.graphStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 90%;
  max-width: none;
  background: rgba(68, 192, 255, 0.2);
  margin-left: 20px;
  padding: 8px;
}
.figmaPaste2 {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 25%;
  height: 100%;
  left: auto;
  top: auto;
  align-self: center;
  margin-left: 0px;
  min-height: 0;
}
.figmaPaste2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0;
  margin-top: calc(0px - 8%);
  height: calc(100% + 8%);
}
.figmaPaste2 > :global(.__wab_flex-container) > *,
.figmaPaste2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste2 > :global(.__wab_flex-container) > picture > img,
.figmaPaste2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8%;
}
@media (max-width: 1440px) {
  .figmaPaste2 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.text__nE3Ae {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin-top: calc(0% + 8%) !important;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
}
@media (max-width: 1440px) {
  .text__nE3Ae {
    font-size: 1.16vw;
    margin-top: calc(21% + 8%) !important;
  }
}
@media (max-width: 1370px) {
  .text__nE3Ae {
    margin-top: calc(19% + 8%) !important;
  }
}
.text__gUZx {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
}
@media (max-width: 1440px) {
  .text__gUZx {
    font-size: 1.3vw;
  }
}
.text__ksGaZ {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin-top: calc(10% + 8%) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .text__ksGaZ {
    font-size: 1.16vw;
    margin-top: calc(20% + 8%) !important;
  }
}
@media (max-width: 1370px) {
  .text__ksGaZ {
    margin-top: calc(18% + 8%) !important;
  }
}
.text___5MlV {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
}
@media (max-width: 1440px) {
  .text___5MlV {
    font-size: 1.3vw;
  }
}
.text__cjOaK {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin-top: calc(10% + 8%) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
@media (max-width: 1440px) {
  .text__cjOaK {
    font-size: 1.16vw;
    margin-top: calc(20% + 8%) !important;
  }
}
@media (max-width: 1370px) {
  .text__cjOaK {
    margin-top: calc(18% + 8%) !important;
  }
}
.text__nTpqi {
  display: block;
  font-size: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  text-align: center;
  margin-bottom: 2%;
}
@media (max-width: 1440px) {
  .text__nTpqi {
    font-size: 1.3vw;
  }
}
.freeBox__c7KmL {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  margin-top: 0px;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__c7KmL > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 4%);
  width: calc(100% + 4%);
}
.freeBox__c7KmL > :global(.__wab_flex-container) > *,
.freeBox__c7KmL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__c7KmL > :global(.__wab_flex-container) > picture > img,
.freeBox__c7KmL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4%;
}
.unresolvedTickets {
  width: 37%;
  height: 80%;
  display: block;
  overflow: hidden;
  max-width: 567px;
  max-height: 340px;
}
.cardsDefault {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.sheet2 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
  border-radius: 8px;
}
.freeBox__kzWen {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 540px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 15px;
  padding: 0px 0px 12px;
}
.freeBox__kzWen > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__kzWen > :global(.__wab_flex-container) > *,
.freeBox__kzWen > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kzWen > :global(.__wab_flex-container) > picture > img,
.freeBox__kzWen
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.text___6KqyO {
  display: block;
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-top: 32px;
  padding-left: 35px;
}
.viewDetails {
  display: block;
  font-size: 14px;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3555ff;
  position: absolute;
  top: 34px;
  left: auto;
  align-self: center;
  padding-left: 0px;
  margin-left: calc(0px + 0px) !important;
  margin-top: 0px;
  right: 19px;
}
.dashboardPaymentsCard:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  width: 100%;
  height: 17%;
  margin-bottom: 5px;
  min-width: 0;
}
.unresolvedTickets2 {
  width: 37%;
  height: 80%;
  display: block;
  overflow: hidden;
  max-width: 567px;
  max-height: 340px;
}
.cardsDefault2 {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.sheet3 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
  border-radius: 8px;
}
.freeBox__sli7K {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 540px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 15px;
  padding: 0px 0px 12px;
}
.freeBox__sli7K > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__sli7K > :global(.__wab_flex-container) > *,
.freeBox__sli7K > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sli7K > :global(.__wab_flex-container) > picture > img,
.freeBox__sli7K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.text__buLt5 {
  display: block;
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  padding-top: 32px;
  padding-left: 35px;
}
.viewDetails2 {
  display: block;
  font-size: 14px;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3555ff;
  position: absolute;
  top: 61.9%;
  left: auto;
  align-self: center;
  padding-left: 0px;
  margin-left: calc(0px + 0px) !important;
  margin-top: 0px;
  right: 5.23%;
}
.dashboardContractsCard:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  align-self: center;
  width: 100%;
  height: 17%;
  margin-bottom: 5px;
  min-width: 0;
}
