.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(185, 185, 185, 1);
  width: 100%;
  height: 4vw;
  display: flex;
  flex-direction: row;
  padding-top: 0%;
  padding-bottom: 0%;
  position: relative;
  cursor: pointer;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0%;
}
.root:hover {
  background: #f7f7f7;
}
.root:active {
  background: #f7f7f7;
  box-shadow: none;
  border: 1px solid #000000;
}
.freeBox__gv2O4 {
  display: flex;
  width: 11%;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: calc(10.5% + 0%) !important;
  justify-content: center;
  flex-direction: row;
}
.slotTargetAmount {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 700;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
.paymentStatusStack {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 4vw;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  margin-left: calc(12% + 0%) !important;
  flex-shrink: 0;
  padding: 0px;
}
.paymentStatusStack > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.paymentStatusStack > :global(.__wab_flex-container) > *,
.paymentStatusStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.paymentStatusStack > :global(.__wab_flex-container) > picture > img,
.paymentStatusStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
.rectangle56 {
  background: rgba(123, 198, 96, 0.709);
  width: 2.8vw;
  height: 1.4vw;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
}
.text {
  display: block;
  font-size: 0.78vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(29, 112, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
.freeBox___76Wkm {
  display: flex;
  width: 10%;
  position: relative;
  left: auto;
  top: auto;
  height: auto;
  margin-left: calc(13% + 0%) !important;
  margin-top: 0%;
  margin-bottom: 0%;
  justify-content: center;
  flex-direction: row;
}
.slotTargetPaymentType {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 700;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 140%;
  letter-spacing: 0.3px;
}
.paymentDate {
  display: flex;
  width: 12.33%;
  position: relative;
  left: auto;
  top: auto;
  height: auto;
  margin-left: calc(13% + 0%) !important;
  flex-direction: row;
}
.slotTargetPaymentDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 700;
  color: rgba(77, 77, 77, 1);
  line-height: 140%;
  letter-spacing: 0.3px;
}
