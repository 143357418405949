.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__fFaEb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.companyName {
  display: flex;
  width: auto;
  height: 58px;
  margin-bottom: 2%;
  padding-top: 0px;
  margin-top: 0%;
  justify-content: center;
  flex-direction: row;
  flex-shrink: 0;
}
.slotTargetMerchantName {
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
.rectangle29 {
  background: rgba(255, 255, 255, 1);
  width: 412px;
  height: 134px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-bottom: 2%;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin: 0px;
}
.freeBox__mnJiQ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}
.freeBox__bOZsT {
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
.slotTargetAmount {
  font-family: "Inter", sans-serif;
  font-size: 19.35px;
  font-weight: 800;
  color: #000000;
}
.freeBox__gybrI {
  position: relative;
  left: auto;
  top: auto;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
}
.slotTargetSubscriptionType {
  font-family: "Inter", sans-serif;
  font-size: 18.15px;
  font-weight: 700;
  color: #000000;
}
.svg___2Z4R {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.9990125124341537, 0.9990075074918353)
    rotate(-0.28676326876410735deg) skew(-0.05735167682688388deg, 0deg);
  width: 241px;
  height: 16px;
  display: block;
  transform-origin: top left;
  left: auto;
  top: auto;
  margin-bottom: 16.5px;
  margin-top: 16.5px;
  flex-shrink: 0;
}
.viewDetails {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(95, 86, 86, 1);
  position: relative;
  left: auto;
  top: auto;
  cursor: pointer;
  padding-bottom: 0px;
}
.rectangle28 {
  background: rgba(255, 255, 255, 1);
  width: 412px;
  height: 377px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste2 {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.text__tsAf7 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-weight: 700;
  margin-top: 28px;
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.handleBankTransfer:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: 36px;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.handleCardPayment:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
  margin: 28px 0px 0px;
}
.svg__an9Tu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 241px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: auto;
  top: auto;
  margin-top: 36px;
  flex-shrink: 0;
}
.subscriptionText {
  display: block;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.6;
  color: rgba(95, 86, 86, 1);
  position: relative;
  left: auto;
  top: auto;
  cursor: default;
  padding-bottom: 0px;
  margin-top: 18px;
  width: 270px;
  padding-top: 0px;
  text-align: center;
  margin-bottom: 0px;
}
.figmaPaste3 {
  display: flex;
  position: relative;
  width: 337px;
  height: 127px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.text__epgiS {
  display: block;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-top: 50px;
}
.sideBarInstance {
  display: flex;
  position: relative;
  width: 431px;
  height: 100%;
  left: auto;
  top: auto;
  background: #ffffff;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0;
}
.freeBox__jRaqs {
  display: block;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 149px;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.closeButton {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: auto;
  position: absolute;
  left: 372px;
  top: 28px;
  cursor: pointer;
  height: auto;
}
.closeButton2 {
  display: flex;
  width: auto;
  position: absolute;
  left: 40px;
  top: 28px;
  cursor: default;
  height: auto;
  flex-direction: row;
}
.slotTargetSubscriptionDate {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: rgba(112, 112, 112, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox__aknhO {
  display: flex;
  position: absolute;
  left: 42px;
  top: 68px;
}
.slotTargetSidebarAmount {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #000000;
}
.invoiceItemStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 9px;
  min-width: 0;
  padding: 0px;
}
.invoiceItemStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.invoiceItemStack > :global(.__wab_flex-container) > *,
.invoiceItemStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.invoiceItemStack > :global(.__wab_flex-container) > picture > img,
.invoiceItemStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
