.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__pZbeA {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__sNaLc {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__goSoz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__e0Rjz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__dr8NT {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__rSoc0 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg___2XFn {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__pwCwn {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___702GQ {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__ewy4M {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__u0Qb {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__wmWgw {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__sg5I {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__p1Dx6 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__w4Sm7 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__hfhld {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.freeBox__rg6CQ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__rg6CQ > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__rg6CQ > :global(.__wab_flex-container) > *,
.freeBox__rg6CQ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rg6CQ > :global(.__wab_flex-container) > picture > img,
.freeBox__rg6CQ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text___7Khqf {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__hBnr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__wjqz {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__bgu5U {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__lv71U {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__uc2Ab {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1153px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.freeBox__tgUm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  margin: 40px 0px 20px;
}
.slotTargetOwnerText {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  text-align: left;
  line-height: 2;
}
.freeBox__m0Jc6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__ww6Jy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text___4NQe7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__fcbaW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__x3Qr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__dsZsj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__bfT5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.countrySelect:global(.__wab_instance) {
  max-width: 100%;
  width: 462px;
  margin-top: 0px;
  height: 41px;
  flex-shrink: 0;
}
.option__y1Xyz:global(.__wab_instance) {
  position: relative;
}
.text__ffk9 {
  padding-bottom: 0px;
}
.option__uN1C4:global(.__wab_instance) {
  position: relative;
}
.option__r96O:global(.__wab_instance) {
  position: relative;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option___13Uib:global(.__wab_instance) {
  position: relative;
}
.option__q3LDh:global(.__wab_instance) {
  position: relative;
}
.option__tDgtD:global(.__wab_instance) {
  position: relative;
}
.option__pG1B:global(.__wab_instance) {
  position: relative;
}
.option__aJcJx:global(.__wab_instance) {
  position: relative;
}
.option___7JGCs:global(.__wab_instance) {
  position: relative;
}
.option__qQjPp:global(.__wab_instance) {
  position: relative;
}
.option__pRPkW:global(.__wab_instance) {
  position: relative;
}
.option__v2Fp:global(.__wab_instance) {
  position: relative;
}
.option__dmVd9:global(.__wab_instance) {
  position: relative;
}
.option__rwl14:global(.__wab_instance) {
  position: relative;
}
.option__u1Q8E:global(.__wab_instance) {
  position: relative;
}
.option___7Fqua:global(.__wab_instance) {
  position: relative;
}
.option__xJpZh:global(.__wab_instance) {
  position: relative;
}
.option___4ZGkK:global(.__wab_instance) {
  position: relative;
}
.option__baKmq:global(.__wab_instance) {
  position: relative;
}
.option__tsA:global(.__wab_instance) {
  position: relative;
}
.option__sCAxG:global(.__wab_instance) {
  position: relative;
}
.option__iyYuI:global(.__wab_instance) {
  position: relative;
}
.option__g4Jc5:global(.__wab_instance) {
  position: relative;
}
.option__wj03V:global(.__wab_instance) {
  position: relative;
}
.option___4ADoR:global(.__wab_instance) {
  position: relative;
}
.option__wIPzI:global(.__wab_instance) {
  position: relative;
}
.option___2Tj1Z:global(.__wab_instance) {
  position: relative;
}
.option___4Xs13:global(.__wab_instance) {
  position: relative;
}
.option__tHvJo:global(.__wab_instance) {
  position: relative;
}
.option__jIkX9:global(.__wab_instance) {
  position: relative;
}
.option__pFy3O:global(.__wab_instance) {
  position: relative;
}
.option__j1Cd0:global(.__wab_instance) {
  position: relative;
}
.option__g5Br:global(.__wab_instance) {
  position: relative;
}
.option___9A1D:global(.__wab_instance) {
  position: relative;
}
.option__u9DQi:global(.__wab_instance) {
  position: relative;
}
.option__vJcoI:global(.__wab_instance) {
  position: relative;
}
.option__rfDcB:global(.__wab_instance) {
  position: relative;
}
.option__wZ2A:global(.__wab_instance) {
  position: relative;
}
.option__c5Wqv:global(.__wab_instance) {
  position: relative;
}
.option___2U3Tw:global(.__wab_instance) {
  position: relative;
}
.option___2UkSm:global(.__wab_instance) {
  position: relative;
}
.option__twWf:global(.__wab_instance) {
  position: relative;
}
.option__osHyf:global(.__wab_instance) {
  position: relative;
}
.option__jSg:global(.__wab_instance) {
  position: relative;
}
.option__sZfp5:global(.__wab_instance) {
  position: relative;
}
.option__oshtz:global(.__wab_instance) {
  position: relative;
}
.option__tx2Iw:global(.__wab_instance) {
  position: relative;
}
.option__r0Ox2:global(.__wab_instance) {
  position: relative;
}
.option__avX7O:global(.__wab_instance) {
  position: relative;
}
.option__nToLq:global(.__wab_instance) {
  position: relative;
}
.option___246D:global(.__wab_instance) {
  position: relative;
}
.option__vlIjd:global(.__wab_instance) {
  position: relative;
}
.option__nkSzB:global(.__wab_instance) {
  position: relative;
}
.option__wDzPv:global(.__wab_instance) {
  position: relative;
}
.option__x8Hu9:global(.__wab_instance) {
  position: relative;
}
.option___7PPsP:global(.__wab_instance) {
  position: relative;
}
.option__dqcVu:global(.__wab_instance) {
  position: relative;
}
.option__qDa5Q:global(.__wab_instance) {
  position: relative;
}
.option__pmT1:global(.__wab_instance) {
  position: relative;
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__erZuC {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__t5I8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.ssnStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__pqG5Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.passportStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__sayCd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.passport {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 13.5px;
    margin-left: 80px;
    margin-top: -15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    margin-bottom: -10px;
    align-self: flex-start;
  }
}
.continueButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 49px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .continueButton:hover {
  background: #3555ffd4;
}
.root .continueButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__ogEtJ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
