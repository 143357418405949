.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
.freeBox__aNm6X {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px;
}
.freeBoxpostClick__aNm6XF0H6O {
  align-items: center;
  justify-content: center;
}
@media (max-width: 1440px) {
  .timeSelect:global(.__wab_instance) {
    height: auto;
  }
}
.text {
  margin-top: 0px;
}
.dropdownIcon2 {
  position: relative;
  object-fit: cover;
  width: 2vw;
  height: 2vw;
  color: #000000;
  align-self: auto;
  left: auto;
  top: auto;
  flex-shrink: 0;
  margin: 0px;
}
.dropdownIcon2postClick {
  left: auto;
  top: auto;
}
.dropdownicon {
  position: relative;
  object-fit: cover;
  width: 2vw;
  height: 2vw;
  color: #000000;
  align-self: flex-end;
  left: auto;
  top: auto;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0px;
}
.dropdowniconpostClick {
  display: none;
}
.freeBox__vl2Yj {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 10vw;
  height: auto;
  max-width: none;
  position: relative;
  left: auto;
  top: auto;
  padding: 0px;
}
.freeBoxpostClick__vl2Yjf0H6O {
  left: -23%;
  top: 132%;
  position: absolute;
  overflow: hidden;
  background: none;
  box-shadow: 0px 4px 16px 0px #00000021;
  border-radius: 0.5vw;
  margin: 0vw 0vw 0px;
}
.freeBox__whj3D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px #00000021;
  border-radius: 0.5vw;
  padding: 0px;
}
.lastWeek:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.lastMonth:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.last3Months:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.lastYear:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
