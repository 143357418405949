.root {
  background: #c2c2c2b5;
  width: 6.5vw;
  height: 1.8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100px;
}
.text {
  display: block;
  font-size: 0.75vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #595959;
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  padding-top: 0px;
}
