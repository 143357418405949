.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px 0px 0px 23%;
}
.rootpostClick {
  background: #980f0f00;
}
.createNewButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  width: 7.9vw;
  height: 2.1vw;
  align-self: center;
  max-width: none;
  max-height: none;
  flex-shrink: 0;
  margin: 0px 0px 0px 0%;
}
.createNewButtonpostClick:global(.__wab_instance) {
  flex-shrink: 0;
}
.createNewElement:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  margin-top: 1.3vw;
  margin-left: 1.3vw;
  width: 11vw;
  left: 15vw;
  top: 1vw;
  z-index: 1;
}
