.root {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3b3;
  font-size: 16.4px;
  width: 400px;
  height: 48px;
  max-width: none;
  position: relative;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 414px) {
  .root {
    height: 45px;
    font-size: 15.5px;
  }
}
.rootdanger {
  color: #535353;
  box-shadow: inset 0px 0px 0px 1px #ff0000;
}
