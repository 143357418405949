.root {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1370px) {
  .root {
    font-size: 15px;
  }
}
.rootdanger {
  box-shadow: inset 0px 0px 0px 1px #ff0505;
}
