.root {
  background: #4873ecb5;
  width: 3.5vw;
  height: 1.4vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100px;
}
.text {
  display: block;
  font-size: 0.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 100%;
  color: #0112c2;
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  margin-top: 0vw;
}
