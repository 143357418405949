.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 516px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0px;
  padding-top: 0px;
}
@media (max-width: 414px) {
  .root {
    width: 335px;
  }
}
.closeButton {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  color: #3555ff;
  font-size: 20px;
  margin-right: 0px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 8px;
}
@media (max-width: 1370px) {
  .closeButton {
    font-size: 18px;
    height: auto;
    line-height: 1;
  }
}
