.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.freeBox {
  display: flex;
  position: relative;
  transition-property: all;
  transition-duration: 0.2s;
  align-items: center;
  justify-content: center;
  height: auto;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
}
.svg {
  position: relative;
  object-fit: cover;
  color: #000000;
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 20px;
}
@media (max-width: 1440px) {
  .svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}
.svgisChecked {
  color: #0091ff;
}
.svgisIndeterminate {
  color: #0091ff;
}
.root:hover .svg {
  color: #c8c7c1;
}
.rootisDisabled:hover .svgisDisabled {
  color: #dbdbd7;
}
.rootisChecked:hover .svgisChecked {
  color: #0081f1;
}
.rootisIndeterminate:hover .svgisIndeterminate {
  color: #0081f1;
}
