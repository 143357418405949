.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 999;
  align-self: flex-start;
  height: 100%;
  max-width: none;
  flex-shrink: 0;
  min-height: 0;
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__mSe0P {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: flex-start;
  overflow: visible;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 8px 0px;
  border-width: 0px;
  border-style: solid;
}
@media (max-width: 1440px) {
  .freeBox__mSe0P {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__oqBjO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 842px;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  left: auto;
  top: auto;
  z-index: 1;
  padding: 0px 8px;
  margin: 50px 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox__oqBjO {
    width: 52vw;
    max-width: none;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 3.5vw;
  }
}
@media (max-width: 1370px) {
  .freeBox__oqBjO {
    width: 53vw;
  }
}
.freeBox___6TIxt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___8Cwu6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px;
}
.applicationKeys:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 914px;
  width: 605px;
  margin-bottom: 100px;
  flex-shrink: 0;
}
