.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 458px;
  height: 80px;
  max-width: 100%;
  background: #f2f2f2;
  box-shadow: 0px 0px 0px 1px #a3a3a3;
  position: relative;
  border-radius: 7px;
  padding: 0px;
}
.controllerName {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
}
.slotTargetControllerName {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}
.deleteController {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-left: 0px;
  font-size: 20px;
  color: #3555ff;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  cursor: pointer;
  left: 416px;
  top: 25px;
}
