.root {
  background: rgba(255, 255, 255, 1);
  width: 431px;
  height: 1047px;
  display: block;
  position: relative;
  border-radius: 0px;
}
.figmaPaste4 {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__pF3J8 {
  display: flex;
  position: absolute;
  width: 256px;
  left: 44px;
  top: 139px;
}
.slotTargetContractName {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox___6Amni {
  display: flex;
  position: absolute;
  width: 151px;
  left: 44px;
  top: 57px;
}
.slotTargetAmount {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.closeButton {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: 29px;
  position: absolute;
  left: 382px;
  top: 27px;
}
.text__aoXm1 {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: 63px;
  position: absolute;
  left: 319px;
  top: 142px;
}
.milestoneNameStack {
  display: flex;
  position: absolute;
  width: 329px;
  left: 44px;
  top: 214px;
}
.slotTargetMilestoneName {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.svg__ci4Sq {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 38px;
  top: 289px;
}
.downloadContract {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 75.199px;
  height: 92.16px;
  display: block;
  left: 172px;
  top: 420px;
}
._212433597315589653811 {
  transform: scale(0.9991621490028533, 1.0001439716239153)
    rotate(88.96775530843426deg) skew(-0.05836755325978498deg, 0deg);
  width: 15px;
  height: 9px;
  display: block;
  transform-origin: top left;
  overflow: hidden;
  position: absolute;
  left: 241.8409999999999px;
  top: 494.746px;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 29px;
  height: 26px;
  display: block !important;
  left: 222.59899999999993px;
  top: 495.079px;
}
.img > picture > img {
  object-fit: cover;
}
.layer2 {
  transform: scale(0.9991621490028533, 1.0001439716239153)
    rotate(88.96775530843426deg) skew(-0.05836755325978498deg, 0deg);
  width: 17px;
  height: 11px;
  display: block;
  transform-origin: top left;
  position: absolute;
  left: 242.048px;
  top: 500px;
}
.svg__wOmqp {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #35353d;
  width: 16.031px;
  height: 10.05px;
  display: block;
  transform-origin: top left;
  top: 0px;
  left: 0px;
}
.text__q8DYo {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 194px;
  position: absolute;
  left: 128px;
  top: 347px;
}
.svg__wCg69 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 44px;
  top: 567px;
}
