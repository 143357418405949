.root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
.item {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  font-size: 16.4px;
  width: 328px;
  height: 40px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 0px 2px 12px;
  border: 0px solid #b7b6b6;
}
@media (max-width: 1440px) {
  .item {
    font-size: 0.97vw;
    width: 20vw;
    height: 2.29vw;
    flex-shrink: 0;
  }
}
.quantity {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  font-size: 16.4px;
  width: 95px;
  height: 40px;
  max-width: 100%;
  margin-left: 49px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 0px 2px 12px;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .quantity {
    font-size: 0.97vw;
    height: 2.29vw;
    width: 5.9vw;
    margin-left: 3.3vw;
    flex-shrink: 0;
  }
}
.pricing {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 169px;
  height: 40px;
  max-width: 100%;
  background: #ffffff;
  margin-left: 49px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .pricing {
    height: 2.29vw;
    width: 9.2vw;
    margin-left: 3.3vw;
    flex-shrink: 0;
  }
}
.deleteField {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #3555ff;
  font-weight: 700;
  margin-left: 21px;
  padding-right: 0px;
  font-size: 19px;
  cursor: pointer;
  min-width: 0;
}
@media (max-width: 1440px) {
  .deleteField {
    font-size: 1.15vw;
    margin-left: 1.5vw;
  }
}
.deleteFieldfirstone {
  display: none;
}
