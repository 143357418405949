.spinner2 {
  width: 300px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  }
  

  .half-spinner2 {
    width: 50px;
    height: 50px;
    border: 5px solid #757575;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
  }


  .spinner4 {
    width: 800vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    }
    
  
    .half-spinner4 {
      width: 50px;
      height: 50px;
      border: 5px solid #757575;
      border-top: 3px solid transparent;
      border-radius: 50%;
      animation: spin 0.5s linear 0s infinite;
    }
  
  .completed {
    font-size: 5rem;
    color: #03fc4e;
  
    animation: bigger 1s linear;
  }
  
  @keyframes bigger {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(2);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }