.root {
  display: block;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: 17px;
  height: 20px;
  position: relative;
  cursor: pointer;
  border-width: 0px;
}
@media (max-width: 1440px) {
  .root {
    font-size: 0.9vw;
    color: #3555ff;
  }
}
.rootfirstOne {
  color: #3555ff00;
  cursor: default;
}
