.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__mWoEw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__csLvo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__tcBc0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__krFzv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__gzNoZ {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__dNqFp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__cXJeL {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__dzGio {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__fXrul {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__eFpp {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___0KwhD {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__cPfM {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__lrUhk {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___1RDbs {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__bjeNs {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__sc8Co {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.freeBox__xeByO {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__xeByO > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__xeByO > :global(.__wab_flex-container) > *,
.freeBox__xeByO > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xeByO > :global(.__wab_flex-container) > picture > img,
.freeBox__xeByO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text___4TrAp {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__g17Mo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__ts0TV {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__nv1VP {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__bP1Lz {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow: visible;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.text___9KzN7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 40px 0px 20px 20px;
}
.controllerStack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 0px;
  padding-top: 0px;
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 50px;
  min-width: 0;
}
.text___9G3Mb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  color: #363636;
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0px;
  text-align: left;
  padding-top: 0px;
  min-width: 0;
  margin: 20px 0px 0px;
}
.text__pkief {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 30px 0px 0px;
}
.freeBox___5CwsK {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 0px;
  margin-top: 15px;
  min-width: 0;
  flex-shrink: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox___5CwsK {
    height: 61px;
    flex-shrink: 0;
  }
}
.licenseCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.title9 {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13px;
  align-self: flex-start;
  margin-top: 23px;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title9 {
    font-size: 15.4px;
    width: auto;
  }
}
.freeBox__vJ8JZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 0px;
  margin-top: 10px;
  min-width: 0;
  flex-shrink: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1440px) {
  .freeBox__vJ8JZ {
    height: 61px;
    flex-shrink: 0;
  }
}
.idCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.title11 {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13px;
  align-self: flex-start;
  margin-top: 23px;
}
@media (max-width: 1440px) {
  .title11 {
    font-size: 15.4px;
    width: auto;
  }
}
.errorText {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 20px 0px -10px;
  }
}
.controllerContentError {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .controllerContentError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 20px 0px -10px;
  }
}
.uploadController {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  max-width: 100%;
  background: #3555fff2;
  margin-top: 40px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .uploadController:hover {
  background: #3555ffc7;
}
.root .uploadController:active {
  background: #3555ffc7;
  border: 1px solid #000000;
}
.text__oSvFs {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.uploadControllerSuccess {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #019500;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4px;
  align-self: flex-start;
  margin-top: 23px;
}
@media (max-width: 1440px) {
  .uploadControllerSuccess {
    font-size: 15.4px;
    width: auto;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .uploadControllerSuccess {
    left: auto;
    top: auto;
  }
}
.businessStack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: visible;
  min-width: 0;
  padding: 0px 0px 50px 20px;
}
.text__iyEid {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  color: #363636;
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 0px;
}
.businessContentError {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .businessContentError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 20px 0px -10px;
  }
}
.uploadBusiness {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  max-width: 100%;
  background: #3555fff2;
  margin-top: 40px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .uploadBusiness:hover {
  background: #3555ffc7;
}
.root .uploadBusiness:active {
  background: #3555ffc7;
  border: 1px solid #000000;
}
.text__jzQdj {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.uploadBusinessSuccess {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #019500;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 4px;
  align-self: flex-start;
  margin-top: 23px;
}
@media (max-width: 1440px) {
  .uploadBusinessSuccess {
    font-size: 15.4px;
    width: auto;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .uploadBusinessSuccess {
    left: auto;
    top: auto;
  }
}
.text__jfM21 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 30px 0px 0px 20px;
}
.submitError {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
  padding-bottom: 0px;
}
@media (max-width: 1370px) {
  .submitError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 20px 0px -10px 20px;
  }
}
.submitButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 248px;
  height: 55px;
  max-width: 100%;
  background: #2f4ff9;
  margin-top: 25px;
  cursor: pointer;
  align-self: flex-start;
  margin-left: 20px;
  margin-bottom: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.root .submitButton:hover {
  background: #3555ffd4;
}
.root .submitButton:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__cgjwO {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
}
