.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__fLxml {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.rectangle {
  background: rgba(255, 255, 255, 1);
  width: 530px;
  height: 470px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
}
.freeBox__gG2S {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 50px;
  padding: 0px;
}
.text__rul51 {
  width: auto;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 28px;
}
.text__kHqgt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  margin-top: 30px;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 2;
  min-width: 0;
  padding: 0px 50px;
}
