.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(#ffffff, #ffffff), url("./images/dashboardLoginDarkTheme1Svg.svg"),
    linear-gradient(#ffffff, #ffffff), #ffffff;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
.dashboardLoginDarkTheme {
  background: #4f45fc;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
@media (max-width: 414px) {
  .dashboardLoginDarkTheme {
    justify-content: center;
    align-items: center;
  }
}
.frame1 {
  background: rgba(255, 255, 255, 1);
  width: 540px;
  height: 485px;
  display: flex;
  overflow: hidden;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  margin-bottom: 0vh;
  flex-direction: column;
  box-shadow: 4px 4px 16px 4px #00000033;
  flex-shrink: 0;
  border-radius: 5px;
}
.frame1 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.frame1 > :global(.__wab_flex-container) > *,
.frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1 > :global(.__wab_flex-container) > picture > img,
.frame1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 50px;
}
@media (max-width: 1440px) {
  .frame1 {
    margin: 0% 0% 0vh;
  }
}
@media (max-width: 1370px) {
  .frame1 {
    width: 500px;
    height: 450px;
    flex-shrink: 0;
    margin: 0%;
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (max-width: 1370px) {
  .frame1 > :global(.__wab_flex-container) > *,
  .frame1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frame1 > :global(.__wab_flex-container) > picture > img,
  .frame1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 30px;
  }
}
@media (max-width: 414px) {
  .frame1 {
    margin-bottom: 0%;
    width: 340px;
    height: 415px;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .frame1 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
.text__eMxn {
  display: block;
  font-size: 35px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(24, 24, 24, 1);
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0px + 50px) !important;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}
@media (max-width: 1370px) {
  .text__eMxn {
    font-size: 34px;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 30px) !important;
  }
}
@media (max-width: 414px) {
  .text__eMxn {
    font-size: 26px;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 30px) !important;
  }
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 412px;
  margin-top: calc(0px + 50px) !important;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
@media (max-width: 1370px) {
  .freeBox {
    margin-top: calc(28px + 30px) !important;
  }
}
@media (max-width: 414px) {
  .freeBox {
    width: 250px;
    max-width: 250px;
    flex-wrap: nowrap;
    align-items: center;
    align-content: stretch;
    justify-content: center;
    margin-top: calc(28px + 30px) !important;
  }
}
.slotTargetEmailText {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #2f2f2f;
  text-align: center;
  line-height: 2;
}
@media (max-width: 1370px) {
  .slotTargetEmailText {
    font-size: 23px;
  }
}
@media (max-width: 414px) {
  .slotTargetEmailText {
    font-size: 16px;
    text-align: center;
    color: #2f2f2f;
    font-weight: 500;
  }
}
.resendEmailButton {
  box-shadow: 0px 4px 4px 0px #0000004d;
  background: #3555ff;
  width: 210px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: calc(0px + 50px) !important;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px solid #d3d3d3;
}
@media (max-width: 1370px) {
  .resendEmailButton {
    width: 210px;
    height: 50px;
    margin-top: calc(25px + 30px) !important;
    flex-shrink: 0;
  }
}
@media (max-width: 414px) {
  .resendEmailButton {
    height: 45px;
    width: 250px;
    margin-top: calc(25px + 30px) !important;
    flex-shrink: 0;
  }
}
.root .resendEmailButton:hover {
  background: #3555ffd4;
  margin-top: calc(0px + 50px) !important;
}
.root .resendEmailButton:active {
  background: #3555ffd4;
  margin-top: calc(0px + 50px) !important;
  border-width: 1px;
  border-color: #000000;
}
.text___4HScB {
  display: block;
  font-size: 19px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  transform: translate(0.41900000000001114px, -0.28899999999998727px);
}
@media (max-width: 1370px) {
  .text___4HScB {
    font-size: 17px;
  }
}
@media (max-width: 414px) {
  .text___4HScB {
    font-size: 15.5px;
  }
}
