.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 902px;
  height: auto;
  max-width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 8px;
  border: 0px solid #b8b7b7;
}
.freeBox__yCV3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.text__b3QR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h5 {
  font-size: 16px;
}
.roleName:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  height: 40px;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.freeBox__s1Oem {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 8px;
  min-width: 0;
  padding: 8px 8px 0px 0px;
}
.text__qTrUl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h6 {
  margin-top: 16px;
}
.permissionSelectStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 0px;
}
.permissionSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 326px;
  flex-shrink: 0;
  display: none;
}
.permissionSelectionStack:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 40px;
  pointer-events: auto;
  margin-bottom: 16px;
  overflow: auto;
  padding-bottom: 2px;
  padding-right: 10px;
  border: 1px solid #d9d9d9;
}
.text__lg0Yx {
  position: relative;
  width: 804px;
  height: 25px;
  max-width: 100%;
  margin-bottom: 0px;
  padding-right: 10px;
}
.antdOption:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__gDf7T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__bleYm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  max-width: 100%;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.permissionChipsStack {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  grid-row-gap: var(--token-Tf_KtjkxGdsv);
  width: 100%;
  grid-column-gap: 5px;
  overflow: visible;
  box-shadow: none;
  padding: 0px 8px 8px;
}
.permissionChip:global(.__wab_instance) {
  position: relative;
}
.text__sl7AO {
  width: auto;
}
.freeBox__jCLkZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.addRoleBtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__lw3Nu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ddZgu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
