.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__cXcA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.rectangle {
  background: rgba(255, 255, 255, 1);
  width: 505px;
  height: 460px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
}
.freeBox__xoIiZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 65px;
  padding: 0px;
}
.text___1EjU {
  width: auto;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 26px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #43a047;
  width: 30px;
  height: 24.7px;
  margin-left: 6px;
  flex-shrink: 0;
}
.freeBox__euRch {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}
.slotTargetAmount {
  font-family: "Poppins", sans-serif;
  font-size: 45.5px;
  font-weight: 600;
  color: #000000;
}
.freeBox__wWw4J {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}
.slotTargetDate {
  font-family: "Inter", sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #000000;
}
.downloadReciept {
  background: #3555ff;
  width: 241.8px;
  height: 59.8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 58.5px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
  border: 0px solid #000000;
}
.root .downloadReciept:hover {
  background: #3555ffd4;
}
.root .downloadReciept:active {
  background: #3555ffd4;
  border-width: 1px;
}
.text__ynsg9 {
  display: block;
  font-size: 20.8px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
}
.text__mWoU9 {
  display: block;
  font-size: 23.4px;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-top: 52px;
}
