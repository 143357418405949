.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox___3OE6T {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__fPviG {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__aThKk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__gL4S6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___47OCt {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__b7Tbm {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__x9JiM {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__xYuiB {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__lFk5 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__aDyln {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__ysMvS {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg__q5NmX {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__rAzZ7 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__cublp {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__gcBJc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__gcBJc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__gcBJc > :global(.__wab_flex-container) > *,
.freeBox__gcBJc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gcBJc > :global(.__wab_flex-container) > picture > img,
.freeBox__gcBJc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text___7VeSq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__e1VLs {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__o65D {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__t0PLr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 1051px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  margin-right: 0px;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__m8Eyb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  text-align: left;
  min-width: 0;
  margin: 40px 0px 20px;
}
.text__ts63N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 476px;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  color: #363636;
  line-height: 2;
  font-weight: 500;
  text-align: left;
  min-width: 0;
  margin: 0px;
}
