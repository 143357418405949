.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  min-width: 0;
  padding: 10px;
}
.freeBox__rdMdj {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  background: #ffffff;
  min-width: 0;
  border-radius: 8px;
  padding: 8px;
  border: 2px solid #cac8c8;
}
.freeBox__ojLu4 {
  position: relative;
  width: 50%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  left: auto;
  top: auto;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}
.slotTargetRoleName {
  font-weight: 600;
  font-size: 20px;
}
.freeBox__giO9Y {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  padding: 8px 26px 8px 8px;
}
.chipsStack {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: auto;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-Tf_KtjkxGdsv);
  padding: 8px;
}
.permissionChip__iBCwQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.permissionChip__ekHpV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.permissionChip__bHaD2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__d7EOm {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  left: auto;
  top: auto;
  padding: 8px 12px 8px 8px;
}
.editRoleBtn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 38px;
  height: var(--token-oW80B2Q_Cufz);
  cursor: pointer;
  flex-shrink: 0;
}
