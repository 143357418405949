.root {
  box-shadow: inset 0px 0px 0px 1px #b7b6b6d9;
  background: #ffffff;
  width: 305px;
  height: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .root {
    width: 17.9vw;
    height: 2.29vw;
  }
}
.roottestVariant {
  width: 0px;
  height: 0px;
  background: #ffffff00;
  justify-content: center;
  align-items: center;
}
