.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.freeBox__cyMsJ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 115px;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.goBackArrow {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 45px;
  height: 60px;
  color: #000000;
  margin-top: 3px;
  margin-left: 10%;
  cursor: pointer;
  flex-shrink: 0;
}
.goBackText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 30px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}
.sendButton {
  width: 160px;
  height: 50px;
  background: #3555ff;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-left: 60%;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
}
.root .sendButton:hover {
  background: #3555ffd4;
}
.sendText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
.slotTargetSendText {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__yI8D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.companyName {
  display: flex;
  width: 400px;
  height: 58px;
  margin-bottom: 2%;
  padding-top: 0px;
  margin-top: 0%;
  justify-content: center;
  flex-direction: row;
  flex-shrink: 0;
}
.slotTargetMerchantName {
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
.rectangle29 {
  background: rgba(255, 255, 255, 1);
  width: 392px;
  height: 133px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-bottom: 2%;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  margin: 0px;
}
.freeBox___8U9FU {
  display: flex;
  position: absolute;
  width: 361px;
  left: 22px;
  top: 15px;
  height: auto;
}
.slotTargetEscrowDeposit {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox__xjoTg {
  display: flex;
  position: absolute;
  width: 355px;
  height: auto;
  left: 22px;
  top: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  margin-bottom: 0px;
}
.slotTargetMilestoneOrContract {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 155%;
  letter-spacing: 0.2px;
  text-align: left;
}
.svg__si9Jw {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.9990125124341537, 0.9990075074918353)
    rotate(-0.28676326876410735deg) skew(-0.05735167682688388deg, 0deg);
  width: 241px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 21px;
  top: 98.5px;
}
.viewDetails {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(95, 86, 86, 1);
  position: absolute;
  left: 26px;
  top: 109px;
  cursor: pointer;
}
.rectangle28 {
  background: rgba(255, 255, 255, 1);
  width: 395px;
  height: 358px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste2 {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  min-width: 0;
  min-height: 0;
}
.mainPaymentsCta:global(.__wab_instance) {
  position: absolute;
  top: 15px;
  left: 32px;
}
.svg__r01Mt {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 29px;
  top: 94px;
}
.text__tVfmz {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 193px;
  height: 15px;
  position: absolute;
  left: 109px;
  top: 109px;
}
.card:global(.__wab_instance) {
  position: absolute;
  top: 135px;
  left: 45px;
}
.wire:global(.__wab_instance) {
  position: absolute;
  top: 135px;
  left: 161px;
}
.crypto:global(.__wab_instance) {
  position: absolute;
  top: 135px;
  left: 278px;
}
.freeBox__zwUn {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 372px;
  height: 165px;
  max-width: 100%;
  background: rgba(68, 192, 255, 0.2);
  left: 12px;
  top: 183px;
  padding: 8px;
}
.figmaPaste3 {
  display: flex;
  position: relative;
  width: 337px;
  height: 127px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.freeBox__e3HR {
  display: flex;
  width: 337px;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 7%;
  justify-content: center;
  flex-direction: column;
}
.slotTargetFooterText {
  font-family: "Poppins", sans-serif;
  font-size: 14.5px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 160%;
  letter-spacing: 0.2px;
}
.text__m8BR0 {
  display: block;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-top: 25px;
}
.paymentsSideBar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
