.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  overflow: auto;
  min-width: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 8px 0px 8px 8px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 20%;
  align-self: auto;
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: 12%;
  flex-direction: row;
  min-width: 0;
  margin: 2.2% 1% 1%;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
.title {
  display: block;
  font-size: 1.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
}
.uploadButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: calc(25% + 0%) !important;
  flex-shrink: 0;
}
.addClientButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-bottom: 0%;
  margin-left: calc(2.5% + 0%) !important;
  flex-shrink: 0;
}
.searchVertStack {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3d9;
  background: #ffffff;
  width: 11vw;
  height: 41px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  margin-left: calc(3% + 0%) !important;
  justify-content: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .searchVertStack {
    width: 11vw;
    height: 2.3vw;
    margin-left: calc(3% + 0%) !important;
    flex-shrink: 0;
  }
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  height: 100%;
  max-width: none;
  background: #44c0ff00;
  margin-top: 2%;
  overflow: visible;
  min-height: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1600px) {
  .vertStack {
    width: 76%;
  }
}
.figmaPaste2 {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
  align-self: flex-end;
  padding-bottom: 0px;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
}
.figmaPaste2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.figmaPaste2 > :global(.__wab_flex-container) > *,
.figmaPaste2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste2 > :global(.__wab_flex-container) > picture > img,
.figmaPaste2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
.text__mm6F {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: calc(12% + 0%) !important;
}
.text__nVpfA {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: calc(27% + 0%) !important;
}
.text__y9INt {
  display: block;
  font-size: 1.1vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  margin-left: calc(29% + 0%) !important;
}
.clientsListStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 2%;
  overflow: visible;
  min-width: 0;
  padding: 0px 0px 25px;
}
.clientsListStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.clientsListStack > :global(.__wab_flex-container) > *,
.clientsListStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.clientsListStack > :global(.__wab_flex-container) > picture > img,
.clientsListStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.clientsListCard__yJPlO:global(.__wab_instance) {
  flex-shrink: 0;
}
.clientsListCard__cPbBw:global(.__wab_instance) {
  flex-shrink: 0;
}
.paginationStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  margin-left: 0%;
  margin-top: 0px;
  left: auto;
  top: auto;
  margin-right: 1%;
  padding: 1vw 0px 2vw;
}
@media (max-width: 1440px) {
  .paginationStack {
    left: auto;
    top: auto;
  }
}
.prevButton {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 2vw;
  max-width: 100%;
  background: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__rMtlv {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  color: #000000;
}
.nextButton {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: 2vw;
  max-width: 100%;
  background: #ffffff;
  margin-left: 0.5vw;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text___2He6H {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 400;
}
