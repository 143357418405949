.center {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  height: 527px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 35.5px;
  padding-right: 0px;
}
.figmaPaste {
  display: block;
  position: relative;
  width: 472px;
  height: 140px;
  align-self: auto;
  flex-shrink: 0;
}
.inputField {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 0.5px rgba(204, 204, 204, 1),
    inset 0px 0px 0px 0.5px rgba(204, 204, 204, 1);
  width: 471px;
  height: 92.5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 37px;
  border-radius: 8px;
  padding: 0px;
}
.inputField > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.inputField > :global(.__wab_flex-container) > *,
.inputField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.inputField > :global(.__wab_flex-container) > picture > img,
.inputField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.cardNumber2 {
  height: 46.5px;
  display: flex;
  position: relative;
  align-self: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
._1234123412341234 {
  position: relative;
  width: 332px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  min-height: 0;
}
.cardNumber {
  position: relative;
  width: 469px;
  height: 100%;
  max-width: none;
  color: #565656;
  font-weight: 500;
  border-top-left-radius: 8px;
  margin-left: 1px;
  border-top-right-radius: 8px;
  margin-bottom: 0.9px;
  font-size: 14.8px;
  margin-top: 0.9px;
  min-height: 0;
  padding: 0px 0px 0px 16px;
  border-width: 0px;
}
.center .cardNumber::placeholder {
  color: #818181;
  font-weight: 500;
}
.svg__e9Ze4 {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #ccc;
  width: 471px;
  height: 1px;
  display: block;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.paymentMethodVisa {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(216, 216, 216, 1);
  width: 27px;
  height: 19.9px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 4px;
}
.svg__eBmgL {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #172b85;
  width: 19.14px;
  height: 6px;
  display: block;
  top: 7px;
  left: 4px;
  transform: translate(-0.015000000000000124px, -0.4720000000000004px);
}
.icon {
  opacity: 0;
  width: 5px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.paymentMethodMastercard {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base2 {
  background: #000000;
  box-shadow: inset 0px 0px 0px 1px #000000;
  width: 27px;
  height: 19.9px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 4px;
}
.img__x9VRf {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: none;
  width: 17.33px;
  height: 11.3px;
  display: block !important;
  top: 4px;
  left: 4.8px;
}
.img__x9VRf > picture > img {
  object-fit: cover;
}
.icon2 {
  opacity: 0;
  width: 5px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.paymentMethodAmex {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base3 {
  background: #1e71cc;
  box-shadow: inset 0px 0px 0px 1px #1e71cc;
  width: 27px;
  height: 19.9px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 4px;
}
.svg__df4WS {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 21.75px;
  height: 6px;
  display: block;
  top: 7px;
  left: 2px;
  transform: translate(0.0990000000000002px, -0.4720000000000004px);
}
.icon3 {
  opacity: 0;
  width: 5px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.paymentMethodDiscover {
  width: 27px;
  height: 20px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.base4 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(216, 216, 216, 1);
  width: 27px;
  height: 19.9px;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 4px;
}
.img__vwdHz {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: none;
  width: 23.5px;
  height: 11.5px;
  display: block !important;
  top: 7.94px;
  left: 2.6px;
  margin-top: 0px;
}
.img__vwdHz > picture > img {
  object-fit: cover;
}
.freeBox__tkdq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 45px;
  max-width: 100%;
  margin-top: calc(-9.76px + 10px) !important;
  margin-bottom: 0px;
  margin-left: 0px;
  align-self: auto;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.expDate {
  width: 236px;
  height: 45px;
  max-width: none;
  color: #565656;
  font-weight: 500;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-left: 1px;
  border-bottom-left-radius: 8px;
  font-size: 14.8px;
  margin-right: 0.5px;
  flex-shrink: 0;
  padding: 0px 0px 0px 16px;
  border-width: 0px;
}
.center .expDate::placeholder {
  color: #818181;
}
.cvv {
  width: 232px;
  height: 45px;
  max-width: none;
  color: #565656;
  font-weight: 500;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-left: 1px;
  border-bottom-right-radius: 8px;
  font-size: 14.8px;
  flex-shrink: 0;
  padding: 0px 0px 0px 16px;
  border-width: 0px;
}
.center .cvv::placeholder {
  color: #818181;
  font-weight: 500;
}
.text__psrdc {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #595959;
  position: absolute;
  left: -2px;
  top: 0px;
  width: 132px;
  margin-left: 4px;
}
.svg__jhcze {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: rotate(-90deg);
  width: 46px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 237px;
  top: 129px;
}
.text__myVcp {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: #595959;
  position: relative;
  left: auto;
  top: auto;
  width: 132px;
  margin-left: 0px;
  align-self: flex-start;
  margin-top: 40px;
}
.freeBox__r9Sko {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 471px;
  height: auto;
  max-width: 100%;
  margin-top: 14px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #cccccc;
  border-radius: 8px;
  padding: 0px;
}
.countryStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 45px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.address {
  position: relative;
  width: 100%;
  height: 45px;
  max-width: none;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  box-shadow: none;
  font-size: 14.8px;
  font-weight: 500;
  color: #565656;
  margin-bottom: 1.5px;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px 0px 0px 16px;
  border-top: 1px solid #cccccc;
}
.center .address::placeholder {
  color: #818181;
  font-weight: 500;
}
.freeBox__i4ED3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 1px;
  min-width: 0;
  padding: 0px;
}
.city {
  width: 100%;
  height: 45px;
  max-width: none;
  box-shadow: 0px 0px 0px 1px #cccccc;
  font-size: 14.8px;
  font-weight: 500;
  color: #565656;
  margin-right: 1.5px;
  margin-bottom: 1px;
  min-width: 0;
  padding: 0px 0px 0px 16px;
  border-width: 0px;
}
.center .city::placeholder {
  color: #818181;
  font-weight: 500;
}
.zip {
  position: relative;
  width: 100%;
  height: 45px;
  max-width: none;
  box-shadow: 0px 0px 0px 1px #cccccc;
  font-size: 14.8px;
  font-weight: 500;
  color: #565656;
  margin-bottom: 1px;
  min-width: 0;
  padding: 0px 0px 0px 16px;
  border-width: 0px;
}
.center .zip::placeholder {
  color: #818181;
}
.stateStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 45px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.payButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 471px;
  height: 48px;
  max-width: 100%;
  background: #3555ff;
  margin-top: 51px;
  box-shadow: 0px 0px 0px 1px #3555ff;
  cursor: pointer;
  margin-bottom: 37px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 0px;
  border: 0px solid #3555ff;
}
.center .payButton:hover {
  background: #3555ffd9;
  box-shadow: 0px 0px 0px 1px #3555ffd9;
  border-color: #3555ffd9;
}
.center .payButton:active {
  background: #3555ffd4;
  box-shadow: 0px 0px 0px 1px #ffffff;
  border: 0px solid #ffffff;
}
.text__u8ZIo {
  position: relative;
  width: auto;
  height: auto;
  max-width: none;
  font-weight: 600;
  color: #ffffff;
  font-size: 17px;
}
