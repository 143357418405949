.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ededed;
  padding-bottom: 0px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__kAdLz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 86px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border: 1px solid #cbccd5;
}
.text__sMHa3 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 86px;
}
.freeBox__qchSw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 950px;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 16px 4px #ffffff26;
  min-height: 0;
  padding: 5px 0px 0px;
  margin: var(--token-F1bPDhKSS);
}
.freeBox__cear5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 68px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.lineStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 62px;
  padding: 0px;
}
.circle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg___9Qv8U {
  position: relative;
  object-fit: cover;
  max-width: none;
  color: #3555ff;
  width: 14px;
  flex-shrink: 0;
  height: 1em;
}
.svg___3LPGh {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.svg__dd3Ni {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.2px;
  color: #3555ff;
  flex-shrink: 0;
}
.circle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  align-self: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #3555ff;
}
.svg__gMgnc {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__zdTyU {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.svg__hwFNj {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.svg__cbmF2 {
  object-fit: cover;
  max-width: none;
  width: auto;
  height: 27.5px;
  color: #000000;
  flex-shrink: 0;
}
.circle4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 14.5px;
  height: 14.5px;
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
  border: 1px solid #000000;
}
.freeBox___4TF0I {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox___4TF0I > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox___4TF0I > :global(.__wab_flex-container) > *,
.freeBox___4TF0I > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4TF0I > :global(.__wab_flex-container) > picture > img,
.freeBox___4TF0I
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.text__vMhHc {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(57px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__ayXgu {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #3555ff;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__kxk1Y {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.text__qhJtI {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: calc(40px + 5px) !important;
  margin-left: 20px;
  font-weight: 600;
}
.freeBox__rr0Tw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 2456px;
  max-width: 100%;
  margin-left: 0px;
  overflow: auto;
  min-width: 0;
  padding: 0px 0px 0px 20px;
}
.text__jaOi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 559px;
  font-size: 16.3px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  font-weight: 600;
  padding-bottom: 0px;
  min-width: 0;
  margin: 39px 0px 3px;
}
.freeBox__oezTp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  padding: 0px;
}
.freeBox__cuBbm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  padding: 0px;
}
.text___5MuMc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.firstName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__hPkfi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 46px;
  padding: 0px;
}
.text__dzpZ8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 96px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 5px 4px;
}
.lastName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 208px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.lastNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .lastNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.firstNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .firstNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__dbxHr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__mhWTn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.legalBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.legalBusinessNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .legalBusinessNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__q9JuL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__kMLgH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.displayBusinessName {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.displayBusinessNameError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .displayBusinessNameError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__wd3K0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__rfVky {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.textInput {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.address1 {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 25px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.addressError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .addressError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.cityError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .cityError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.selectState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 15px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__zwZIp:global(.__wab_instance) {
  position: relative;
}
.option__alNa:global(.__wab_instance) {
  position: relative;
}
.option__pxlho:global(.__wab_instance) {
  position: relative;
}
.option___3HRp3:global(.__wab_instance) {
  position: relative;
}
.option__n1MqG:global(.__wab_instance) {
  position: relative;
}
.option__tXd6:global(.__wab_instance) {
  position: relative;
}
.option___9P86D:global(.__wab_instance) {
  position: relative;
}
.option__cx3BZ:global(.__wab_instance) {
  position: relative;
}
.option__bJIqJ:global(.__wab_instance) {
  position: relative;
}
.option__gHzg7:global(.__wab_instance) {
  position: relative;
}
.option__glQph:global(.__wab_instance) {
  position: relative;
}
.option__dRqAu:global(.__wab_instance) {
  position: relative;
}
.option__g4Nyu:global(.__wab_instance) {
  position: relative;
}
.option___4Ywx:global(.__wab_instance) {
  position: relative;
}
.option__tlCrt:global(.__wab_instance) {
  position: relative;
}
.option__aOjO6:global(.__wab_instance) {
  position: relative;
}
.option__rytEg:global(.__wab_instance) {
  position: relative;
}
.option__oMwVv:global(.__wab_instance) {
  position: relative;
}
.option___5Juhd:global(.__wab_instance) {
  position: relative;
}
.option__jYpPg:global(.__wab_instance) {
  position: relative;
}
.option__jExR:global(.__wab_instance) {
  position: relative;
}
.option__xMlE:global(.__wab_instance) {
  position: relative;
}
.option__zyaiy:global(.__wab_instance) {
  position: relative;
}
.option__hemS:global(.__wab_instance) {
  position: relative;
}
.option___0Ljna:global(.__wab_instance) {
  position: relative;
}
.option__hza7Q:global(.__wab_instance) {
  position: relative;
}
.option__fQgVr:global(.__wab_instance) {
  position: relative;
}
.option__yBkcQ:global(.__wab_instance) {
  position: relative;
}
.option__er6Gm:global(.__wab_instance) {
  position: relative;
}
.option__ygYaY:global(.__wab_instance) {
  position: relative;
}
.option__d8Pml:global(.__wab_instance) {
  position: relative;
}
.option__lqDPs:global(.__wab_instance) {
  position: relative;
}
.option__qK1Em:global(.__wab_instance) {
  position: relative;
}
.option__bj5Fp:global(.__wab_instance) {
  position: relative;
}
.option__iZmMq:global(.__wab_instance) {
  position: relative;
}
.option__pyR1L:global(.__wab_instance) {
  position: relative;
}
.option__gllI4:global(.__wab_instance) {
  position: relative;
}
.option__w7L:global(.__wab_instance) {
  position: relative;
}
.option___5Vajj:global(.__wab_instance) {
  position: relative;
}
.option__oxV3:global(.__wab_instance) {
  position: relative;
}
.option___6RVii:global(.__wab_instance) {
  position: relative;
}
.option__ebDge:global(.__wab_instance) {
  position: relative;
}
.option__y9X6U:global(.__wab_instance) {
  position: relative;
}
.option__pxJtz:global(.__wab_instance) {
  position: relative;
}
.option__gXQhA:global(.__wab_instance) {
  position: relative;
}
.option__gqaKr:global(.__wab_instance) {
  position: relative;
}
.option__ogirx:global(.__wab_instance) {
  position: relative;
}
.option__gBQj:global(.__wab_instance) {
  position: relative;
}
.option__l76R:global(.__wab_instance) {
  position: relative;
}
.option__d0Cc:global(.__wab_instance) {
  position: relative;
}
.option__sSoew:global(.__wab_instance) {
  position: relative;
}
.option__mhAeq:global(.__wab_instance) {
  position: relative;
}
.option__zRsMi:global(.__wab_instance) {
  position: relative;
}
.option__tlrC6:global(.__wab_instance) {
  position: relative;
}
.option___7Xqod:global(.__wab_instance) {
  position: relative;
}
.option__lHf:global(.__wab_instance) {
  position: relative;
}
.stateError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .stateError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.zip {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 15px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.zipError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .zipError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__uOU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__k1F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.dob {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 208px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.dobError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .dobError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__dq2S9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___5LvH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.selectIndustry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 462px;
  height: 41px;
  margin-left: 0px;
  flex-shrink: 0;
}
.option__rXOew:global(.__wab_instance) {
  position: relative;
}
.option___7PvdY:global(.__wab_instance) {
  position: relative;
}
.option__oalFf:global(.__wab_instance) {
  position: relative;
}
.option__fQxv8:global(.__wab_instance) {
  position: relative;
}
.option___5U7I5:global(.__wab_instance) {
  position: relative;
}
.option__sucC9:global(.__wab_instance) {
  position: relative;
}
.option__zq9Kx:global(.__wab_instance) {
  position: relative;
}
.option__x7AWt:global(.__wab_instance) {
  position: relative;
}
.option__kjsNo:global(.__wab_instance) {
  position: relative;
}
.option__zusLn:global(.__wab_instance) {
  position: relative;
}
.option___6Wu4C:global(.__wab_instance) {
  position: relative;
}
.option__xCcBa:global(.__wab_instance) {
  position: relative;
}
.option__spPv3:global(.__wab_instance) {
  position: relative;
}
.option__ix8Ke:global(.__wab_instance) {
  position: relative;
}
.option__iA5Q:global(.__wab_instance) {
  position: relative;
}
.option__asm65:global(.__wab_instance) {
  position: relative;
}
.option__k09KU:global(.__wab_instance) {
  position: relative;
}
.option__jo6Q4:global(.__wab_instance) {
  position: relative;
}
.option___0QxBb:global(.__wab_instance) {
  position: relative;
}
.option__kGgGr:global(.__wab_instance) {
  position: relative;
}
.option___4SvJ9:global(.__wab_instance) {
  position: relative;
}
.option___7Snln:global(.__wab_instance) {
  position: relative;
}
.option__b1E0C:global(.__wab_instance) {
  position: relative;
}
.option__i2CHt:global(.__wab_instance) {
  position: relative;
}
.option__giYXt:global(.__wab_instance) {
  position: relative;
}
.industryError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .industryError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox___4Piqu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__qsGEv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.phoneNumber {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.phoneError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .phoneError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__cayb9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__da2Hf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.ssn {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.ssnError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ssnError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__mGlk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text___01MBe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.driversLicense {
  box-shadow: inset 0px 0px 0px 1px #a3a3a3;
  font-size: 16.4px;
  width: 462px;
  height: 41px;
  max-width: 100%;
  margin-top: 0px;
  color: #535353;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border-width: 0px;
}
.freeBox__yxb9N {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__svaZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.driverState:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 0px;
  width: 462px;
  height: 41px;
  flex-shrink: 0;
}
.option__cmJ0G:global(.__wab_instance) {
  position: relative;
}
.option__wDh:global(.__wab_instance) {
  position: relative;
}
.option__tr6RP:global(.__wab_instance) {
  position: relative;
}
.option__j8Qg:global(.__wab_instance) {
  position: relative;
}
.option__bwAnG:global(.__wab_instance) {
  position: relative;
}
.option__tM47W:global(.__wab_instance) {
  position: relative;
}
.option__xCzvC:global(.__wab_instance) {
  position: relative;
}
.option__enGqy:global(.__wab_instance) {
  position: relative;
}
.option___7NZqk:global(.__wab_instance) {
  position: relative;
}
.option__uNTlI:global(.__wab_instance) {
  position: relative;
}
.option__iyAlq:global(.__wab_instance) {
  position: relative;
}
.option__ouRau:global(.__wab_instance) {
  position: relative;
}
.option__yPhmr:global(.__wab_instance) {
  position: relative;
}
.option__rjSBu:global(.__wab_instance) {
  position: relative;
}
.option__dkCGj:global(.__wab_instance) {
  position: relative;
}
.option__hardJ:global(.__wab_instance) {
  position: relative;
}
.option__qEigo:global(.__wab_instance) {
  position: relative;
}
.option__zl3Gq:global(.__wab_instance) {
  position: relative;
}
.option__hNsE:global(.__wab_instance) {
  position: relative;
}
.option__xvBcr:global(.__wab_instance) {
  position: relative;
}
.option__aEmEo:global(.__wab_instance) {
  position: relative;
}
.option__vAqrR:global(.__wab_instance) {
  position: relative;
}
.option__mvq3U:global(.__wab_instance) {
  position: relative;
}
.option___6Z0HN:global(.__wab_instance) {
  position: relative;
}
.option___3H5Sf:global(.__wab_instance) {
  position: relative;
}
.option__ldcQg:global(.__wab_instance) {
  position: relative;
}
.option__zOhV:global(.__wab_instance) {
  position: relative;
}
.option__k3LEw:global(.__wab_instance) {
  position: relative;
}
.option__b0Zvm:global(.__wab_instance) {
  position: relative;
}
.option__walsJ:global(.__wab_instance) {
  position: relative;
}
.option__xfoPd:global(.__wab_instance) {
  position: relative;
}
.option__m55Ol:global(.__wab_instance) {
  position: relative;
}
.option__dNcis:global(.__wab_instance) {
  position: relative;
}
.option__qnif2:global(.__wab_instance) {
  position: relative;
}
.option___3EecL:global(.__wab_instance) {
  position: relative;
}
.option__zeejZ:global(.__wab_instance) {
  position: relative;
}
.option__tux0:global(.__wab_instance) {
  position: relative;
}
.option__iw2Ly:global(.__wab_instance) {
  position: relative;
}
.option__aMwf:global(.__wab_instance) {
  position: relative;
}
.option__xffsG:global(.__wab_instance) {
  position: relative;
}
.option___4PzHv:global(.__wab_instance) {
  position: relative;
}
.option__uJeql:global(.__wab_instance) {
  position: relative;
}
.option__sXnM:global(.__wab_instance) {
  position: relative;
}
.option__fa4Z0:global(.__wab_instance) {
  position: relative;
}
.option___5EzeE:global(.__wab_instance) {
  position: relative;
}
.option__iv78:global(.__wab_instance) {
  position: relative;
}
.option__hbAqz:global(.__wab_instance) {
  position: relative;
}
.option__qm9Zf:global(.__wab_instance) {
  position: relative;
}
.option__rGw0V:global(.__wab_instance) {
  position: relative;
}
.option__qxLi:global(.__wab_instance) {
  position: relative;
}
.option__zwAH:global(.__wab_instance) {
  position: relative;
}
.option__gEcYj:global(.__wab_instance) {
  position: relative;
}
.option__aUc2I:global(.__wab_instance) {
  position: relative;
}
.option__rgSHg:global(.__wab_instance) {
  position: relative;
}
.option__uCFf:global(.__wab_instance) {
  position: relative;
}
.option__uoO:global(.__wab_instance) {
  position: relative;
}
.freeBox__wegOn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__x8FrR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.monthlyVolume {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.volumeError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .volumeError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__uAm9D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__y4L7D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.averageTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.averageError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .averageError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.freeBox__hDAig {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 39px;
  min-width: 0;
  padding: 0px;
}
.text__fpZXr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 510px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #000000;
  line-height: 2;
  padding-bottom: 0px;
  font-weight: 500;
  min-width: 0;
  margin: 0px 0px 6px 4px;
}
.highestTicket {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 462px;
  height: 41px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.highestEstimationError {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .highestEstimationError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.ssnlengtherror {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ssnlengtherror {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.ageError {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .ageError {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.errorText {
  margin-left: 0px;
  margin-top: 35px;
  margin-bottom: -30px;
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: 15px;
}
@media (max-width: 1370px) {
  .errorText {
    font-size: 15px;
    position: relative;
    width: auto;
    height: auto;
    max-width: 800px;
    align-self: flex-start;
    margin: 35px 0px -30px;
  }
}
.continueButton:global(.__wab_instance) {
  position: relative;
  margin-top: 51px;
  margin-bottom: 60px;
  flex-shrink: 0;
}
