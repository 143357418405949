.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.chipStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #3555ff;
  margin-top: 0px;
  border-radius: 8px;
  padding: 6px;
  border: 0px solid #bf4c4c;
}
.chipText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-right: 0px;
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetChildren {
  color: #ffffff;
}
.clearChip:global(.__wab_instance) {
  position: relative;
  align-self: center;
  margin-top: 0px;
  height: 10px;
}
.svg__zbXny {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  padding-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  align-self: center;
  font-size: 10px;
  margin-top: 1px;
  max-width: 8px;
  width: 100%;
  min-width: 0;
}
.svg__p14Vs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
