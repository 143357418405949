.root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  padding: 0px;
}
@media (max-width: 1440px) {
  .root {
    height: auto;
    width: auto;
    display: inline-flex;
  }
}
.rootpostClick {
  cursor: pointer;
}
.freeBox {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  justify-content: center;
  flex-direction: row;
  margin: 0%;
}
@media (max-width: 1440px) {
  .freeBox {
    margin-top: 0%;
    left: auto;
    top: auto;
  }
}
@media (max-width: 1370px) {
  .freeBox {
    margin-top: 0%;
    left: auto;
    top: auto;
  }
}
.slotTargetCurrentSelect {
  font-family: "Open Sans", sans-serif;
  font-size: 1.05vw;
  font-weight: 600;
  color: rgba(36, 38, 51, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.4px;
}
@media (max-width: 1440px) {
  .slotTargetCurrentSelect {
    font-size: 1.16vw;
  }
}
.dropdownIcon3 {
  height: 30%;
  width: 3%;
  right: auto;
  bottom: auto;
  position: relative;
  object-fit: cover;
  color: #000000;
  align-self: flex-end;
  left: auto;
  top: auto;
}
.dropdownIcon3postClick {
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  width: 1.05vw;
  margin-left: 0.6vw;
  height: 1.1vw;
  flex-shrink: 0;
  display: none;
}
.dropdownIcon2 {
  position: relative;
  object-fit: cover;
  width: 3%;
  height: 30%;
  color: #000000;
  align-self: flex-end;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.dropdownIcon2postClick {
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: none;
}
