.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.freeBox__iYUD {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-left: 35px;
  padding: 0px;
}
.freeBox___1X97 {
  display: flex;
  position: relative;
  width: 301px;
  padding-right: 0px;
  margin-right: 12px;
  flex-shrink: 0;
}
.slotTargetItem {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}
.freeBox__zjhNy {
  max-width: none;
  width: 57px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  position: relative;
  left: auto;
  top: auto;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.slotTargetAmount {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}
.freeBox__sv8VX {
  display: flex;
  width: 63px;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 35px;
  flex-direction: row;
}
.slotTargetQuantity {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #707070;
  line-height: 100%;
  letter-spacing: 0.2px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #707070c9;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 377px;
  height: 12px;
  display: block;
  transform-origin: top left;
  left: auto;
  top: auto;
  margin-left: 34.5px;
  margin-top: calc(15px + 10px) !important;
  margin-right: 0px;
  flex-shrink: 0;
}
