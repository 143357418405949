.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 5px;
  min-width: 0;
  padding: 8px 5px 8px 58px;
}
.userName {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.slotTargetUserName {
  font-family: "Open Sans", sans-serif;
  font-size: var(--token-o0Nl0Hefncwa);
  font-weight: 600;
  color: rgba(77, 77, 77, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.3px;
}
