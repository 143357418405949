.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #dedfea;
  width: 100%;
  height: 106px;
  display: block;
  position: relative;
  max-width: 804px;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .root {
    height: 7vw;
  }
}
.title20 {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #1f1f1f;
  width: auto;
  height: auto;
  position: absolute;
  left: 59px;
  top: 23px;
  margin-top: 2%;
  margin-bottom: 2%;
  padding-right: 0px;
}
@media (max-width: 1440px) {
  .title20 {
    font-size: 1.1vw;
  }
}
.dropdownIcon3 {
  left: auto;
  top: 30px;
  position: absolute;
  object-fit: cover;
  width: 6%;
  height: 40%;
  color: #000000;
  align-self: auto;
  right: 6.36%;
  margin: 0px;
}
.dropdownIcon {
  position: absolute;
  object-fit: cover;
  width: 6%;
  height: 40%;
  color: #000000;
  align-self: flex-end;
  left: auto;
  top: 30px;
  right: 6.36%;
  bottom: auto;
  margin: 0px;
}
.dropdownIconopened {
  display: none;
}
.dropdownIcon2 {
  position: absolute;
  object-fit: cover;
  width: 5%;
  height: 40%;
  color: #000000;
  align-self: flex-end;
  left: auto;
  top: auto;
  right: 3.12%;
  bottom: 30.77%;
}
