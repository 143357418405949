.root {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px #000000;
  width: 75px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: 0px solid #000000;
}
.root:hover {
  background: #000000;
}
.root:active {
  background: #000000;
  border-width: 1px;
  border-color: #d2d2d2;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
}
.slotTargetChildren {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.root:hover .slotTargetChildren {
  color: #ffffff;
}
.root:active .slotTargetChildren {
  color: #ffffff;
}
