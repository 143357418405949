.root {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.rootcolor_softGreen {
  border-color: #ffffff00;
}
.trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 1px #a3a3a3;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
  padding: 8px 12px;
  border: 1px solid #dbdbd700;
}
.triggerisOpen {
  background: #e9e9e6;
}
.triggercolor_softBlue {
  background: #edf6ff;
  border-color: #ffffff00;
}
.triggercolor_softCyan {
  background: #e7f9fb;
  border-color: #ffffff00;
}
.triggercolor_softGreen {
  background: #e9f9ee;
  border-color: #ffffff00;
}
.triggercolor_softYellow {
  background: #fffbd1;
  border-color: #ffffff00;
}
.triggercolor_softOrange {
  background: #fff1e7;
  border-color: #ffffff00;
}
.triggercolor_softRed {
  background: #ffefef;
  border-color: #ffffff00;
}
.triggercolor_softPink {
  background: #feeef8;
  border-color: #ffffff00;
}
.triggercolor_softPurple {
  background: #f9f1fe;
  border-color: #ffffff00;
}
.triggercolor_softGray {
  background: #f3f3f2;
}
.triggercolor_clear {
  background: none;
}
.root .trigger:hover {
  background: #eeeeec;
}
.rootcolor_softBlue .triggercolor_softBlue:hover {
  background: #e1f0ff;
}
.rootcolor_softCyan .triggercolor_softCyan:hover {
  background: #d8f3f6;
}
.rootcolor_softGreen .triggercolor_softGreen:hover {
  background: #ddf3e4;
}
.rootcolor_softYellow .triggercolor_softYellow:hover {
  background: #fff8bb;
}
.rootcolor_softOrange .triggercolor_softOrange:hover {
  background: #ffe8d7;
}
.rootcolor_softRed .triggercolor_softRed:hover {
  background: #ffe6e2;
}
.rootcolor_softPink .triggercolor_softPink:hover {
  background: #fce5f3;
}
.rootcolor_softPurple .triggercolor_softPurple:hover {
  background: #f3e7fc;
}
.root .trigger:active {
  background: #e9e9e6;
}
.rootcolor_softBlue .triggercolor_softBlue:active {
  background: #cee7fe;
}
.rootcolor_softCyan .triggercolor_softCyan:active {
  background: #c4eaef;
}
.rootcolor_softGreen .triggercolor_softGreen:active {
  background: #ccebd7;
}
.rootcolor_softYellow .triggercolor_softYellow:active {
  background: #fef2a4;
}
.rootcolor_softOrange .triggercolor_softOrange:active {
  background: #ffdcc3;
}
.rootcolor_softRed .triggercolor_softRed:active {
  background: #fdd8d3;
}
.rootcolor_softPink .triggercolor_softPink:active {
  background: #f9d8ec;
}
.rootcolor_softPurple .triggercolor_softPurple:active {
  background: #eddbf9;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.slotTargetSelectedContent {
  white-space: pre;
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetSelectedContentcolor_softBlue {
  color: #006adc;
}
.slotTargetSelectedContentcolor_softCyan {
  color: #0c7792;
}
.slotTargetSelectedContentcolor_softGreen {
  color: #18794e;
}
.slotTargetSelectedContentcolor_softYellow {
  color: #946800;
}
.slotTargetSelectedContentcolor_softOrange {
  color: #bd4b00;
}
.slotTargetSelectedContentcolor_softRed {
  color: #cd2b31;
}
.slotTargetSelectedContentcolor_softPink {
  color: #cd1d8d;
}
.slotTargetSelectedContentcolor_softPurple {
  color: #793aaf;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: #706f6c;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetPlaceholdershowPlaceholder_color_softBlue {
  color: #006adc;
}
.slotTargetPlaceholdershowPlaceholder_color_softCyan {
  color: #0c7792;
}
.slotTargetPlaceholdershowPlaceholder_color_softGreen {
  color: #18794e;
}
.slotTargetPlaceholdershowPlaceholder_color_softYellow {
  color: #946800;
}
.slotTargetPlaceholdercolor_softOrange_showPlaceholder {
  color: #bd4b00;
}
.slotTargetPlaceholdershowPlaceholder_color_softRed {
  color: #cd2b31;
}
.slotTargetPlaceholdershowPlaceholder_color_softPink {
  color: #cd1d8d;
}
.slotTargetPlaceholdershowPlaceholder_color_softPurple {
  color: #793aaf;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: #90908c;
  align-self: flex-end;
  margin: 4px;
}
.dropdownIconcolor_softBlue {
  color: #0091ff;
}
.dropdownIconcolor_softCyan {
  color: #05a2c2;
}
.dropdownIconcolor_softGreen {
  color: #30a46c;
}
.dropdownIconcolor_softYellow {
  color: #f5d90a;
}
.dropdownIconcolor_softOrange {
  color: #f76808;
}
.dropdownIconcolor_softRed {
  color: #e5484d;
}
.dropdownIconcolor_softPink {
  color: #d6409f;
}
.dropdownIconcolor_softPurple {
  color: #8e4ec6;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 1000;
  width: 100%;
  min-width: 0;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.option__tizk2:global(.__wab_instance) {
  position: relative;
}
.option__psMha:global(.__wab_instance) {
  position: relative;
}
.option___3SWrR:global(.__wab_instance) {
  position: relative;
}
.option__mSi1:global(.__wab_instance) {
  position: relative;
}
.option___2E3Hm:global(.__wab_instance) {
  position: relative;
}
.option__bmAxa:global(.__wab_instance) {
  position: relative;
}
.option__cbjp:global(.__wab_instance) {
  position: relative;
}
.option__uZ8Yg:global(.__wab_instance) {
  position: relative;
}
.option__f1EVf:global(.__wab_instance) {
  position: relative;
}
.option__ay0Pg:global(.__wab_instance) {
  position: relative;
}
.option__ng5PA:global(.__wab_instance) {
  position: relative;
}
.option__jyzmv:global(.__wab_instance) {
  position: relative;
}
.option__teDZb:global(.__wab_instance) {
  position: relative;
}
.option___6DgB:global(.__wab_instance) {
  position: relative;
}
.option__lM2Yy:global(.__wab_instance) {
  position: relative;
}
.option__iYuo7:global(.__wab_instance) {
  position: relative;
}
.option__gMwl7:global(.__wab_instance) {
  position: relative;
}
.option__lpLlr:global(.__wab_instance) {
  position: relative;
}
.option__cK4Wu:global(.__wab_instance) {
  position: relative;
}
.option___2MFn2:global(.__wab_instance) {
  position: relative;
}
.option__xLVdE:global(.__wab_instance) {
  position: relative;
}
.option__vtmNs:global(.__wab_instance) {
  position: relative;
}
.option__mglcG:global(.__wab_instance) {
  position: relative;
}
.option__ch4T0:global(.__wab_instance) {
  position: relative;
}
.option__dpOyK:global(.__wab_instance) {
  position: relative;
}
.option__iyZlw:global(.__wab_instance) {
  position: relative;
}
.option__btmx:global(.__wab_instance) {
  position: relative;
}
.option__wfuhJ:global(.__wab_instance) {
  position: relative;
}
.option__aurDd:global(.__wab_instance) {
  position: relative;
}
.option__jsZzb:global(.__wab_instance) {
  position: relative;
}
.option__pmsXz:global(.__wab_instance) {
  position: relative;
}
.option__a91N3:global(.__wab_instance) {
  position: relative;
}
.option___7N8N0:global(.__wab_instance) {
  position: relative;
}
.option__d0J0R:global(.__wab_instance) {
  position: relative;
}
.option__kqAzr:global(.__wab_instance) {
  position: relative;
}
.option__driRz:global(.__wab_instance) {
  position: relative;
}
.option__qwULw:global(.__wab_instance) {
  position: relative;
}
.option__vy9Jb:global(.__wab_instance) {
  position: relative;
}
.option___38IpG:global(.__wab_instance) {
  position: relative;
}
.option__lchbr:global(.__wab_instance) {
  position: relative;
}
.option__xRqIo:global(.__wab_instance) {
  position: relative;
}
.option__hm1Xk:global(.__wab_instance) {
  position: relative;
}
.option__n9GNf:global(.__wab_instance) {
  position: relative;
}
.option__z8NTm:global(.__wab_instance) {
  position: relative;
}
.option__vCsZi:global(.__wab_instance) {
  position: relative;
}
.option__tYqXk:global(.__wab_instance) {
  position: relative;
}
.option__lz80G:global(.__wab_instance) {
  position: relative;
}
.option__ixHt:global(.__wab_instance) {
  position: relative;
}
.option__t1Ub7:global(.__wab_instance) {
  position: relative;
}
.option__lgzeC:global(.__wab_instance) {
  position: relative;
}
.option__tluri:global(.__wab_instance) {
  position: relative;
}
.option__d5Qu:global(.__wab_instance) {
  position: relative;
}
.option__tjkI:global(.__wab_instance) {
  position: relative;
}
.option__q6Qo4:global(.__wab_instance) {
  position: relative;
}
.option__dqr15:global(.__wab_instance) {
  position: relative;
}
.option__iYwzp:global(.__wab_instance) {
  position: relative;
}
