.root {
  background: #3555ff;
  width: 8.5vw;
  height: 2.3vw;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 10px;
  border-width: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 6%);
  width: calc(100% + 6%);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 6%;
}
.root:hover {
  background: #5b73f2;
}
.root:active {
  background: #5b73f2;
  border-width: 1px;
}
.text___7UNfx {
  display: block;
  font-size: 1.25vw;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  align-self: center;
  margin-bottom: 2%;
}
.text__smrlC {
  display: block;
  font-size: 0.85vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}
