.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: visible;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f6f7fb;
  padding-bottom: 0px;
  overflow-y: visible;
  min-width: 0;
}
.sidebar:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  max-width: none;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 4px 16px 0px #00000033;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 8px 0px 8px 8px;
}
.topBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 20%;
  align-self: auto;
}
@media (max-width: 1600px) {
  .topBar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: 12%;
  flex-direction: row;
  min-width: 0;
  margin: 2.2% 1% 1%;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 0%);
  width: calc(100% + 0%);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0%;
}
.title {
  display: block;
  font-size: 1.8vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(36, 38, 51, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 76%;
  height: 100%;
  max-width: none;
  background: #44c0ff00;
  margin-top: 2%;
  overflow: visible;
  min-height: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1600px) {
  .vertStack {
    width: 76%;
  }
}
.figmaPaste3 {
  display: flex;
  position: relative;
  width: 100%;
  height: 7.2%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin: 0% 0px 0px;
}
.text__qc0Bk {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 5.1%;
}
.text__bq7Bp {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13.15%;
}
.text__rmXq {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 11.85%;
}
.text__f1ICl {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 14.9%;
  padding-left: 0px;
}
.text___59Xv {
  display: block;
  font-size: 1.05vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  left: auto;
  top: auto;
  margin-left: 21%;
}
@media (max-width: 1600px) {
  .text___59Xv {
    margin-left: 20.5%;
  }
}
.paymentsListStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 2%;
  overflow: visible;
  min-width: 0;
  padding: 0px 0px 25px;
}
.paymentsListStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.paymentsListStack > :global(.__wab_flex-container) > *,
.paymentsListStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.paymentsListStack > :global(.__wab_flex-container) > picture > img,
.paymentsListStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.paginationStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  margin-left: 0%;
  margin-top: 0px;
  left: auto;
  top: auto;
  margin-right: 1%;
  padding: 1vw 0px 2vw;
}
@media (max-width: 1440px) {
  .paginationStack {
    left: auto;
    top: auto;
  }
}
.prevButton {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 2vw;
  max-width: 100%;
  background: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__nbuKi {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  color: #000000;
}
.nextButton {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: 2vw;
  max-width: 100%;
  background: #ffffff;
  margin-left: 0.5vw;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__f4Xm5 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.9vw;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 400;
}
