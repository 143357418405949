.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 10vw;
  height: auto;
  max-width: none;
  position: relative;
  padding: 0px;
}
.rootpostClick {
  width: auto;
  display: inline-flex;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fffefe;
  width: 1.5vw;
  height: 1vw;
  display: block;
  margin-top: 0.25vw;
  margin-bottom: -0.5vw;
  align-self: flex-end;
  margin-right: 0.6vw;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  flex-shrink: 0;
  border-width: 0px;
}
.signOutButton:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.signOutButtonpostClick:global(.__wab_instance) {
  flex-shrink: 0;
}
