.root {
  background: #3555ff;
  width: 241px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px;
  border: 2px solid #3555ff;
}
.root:hover {
  background: #3555ffd4;
  border-color: #3555ff00;
}
.root:active {
  background: #3555ffd4;
  box-shadow: none;
  border-width: 2px;
  border-color: #ffffff;
}
.text {
  display: block;
  font-size: 15.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  padding-bottom: 0px;
}
.root:hover .text {
  color: #ffffff;
}
.root:active .text {
  color: #ffffff;
}
