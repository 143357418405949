.root {
  background: #000000;
  width: 321px;
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border-width: 0px;
}
.root:hover {
  background: #000000d4;
  border: 0px solid #d3d3d3;
}
.root:active {
  background: #000000d4;
  border: 1px solid #d3d3d3;
}
.text {
  display: block;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
}
