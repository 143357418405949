.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.sidebar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1600px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 1440px) {
  .sidebar:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.rectangle34 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 180px;
  height: 100%;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  padding-right: 7px;
  padding-left: 7px;
  flex-shrink: 0;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: #cecfd8;
  border-left-color: #cecfd8;
  min-height: 0;
  border-width: 1px;
}
@media (max-width: 1440px) {
  .rectangle34 {
    width: 13vw;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  left: auto;
  top: auto;
  flex-direction: column;
  max-width: none;
  min-width: 0;
  min-height: 0;
  padding: 110px 0px 0px;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2px;
}
@media (max-width: 1600px) {
  .figmaPaste {
    padding-top: 7.6vw;
  }
}
.title22 {
  display: block;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(15% + 2px) !important;
  margin-bottom: 9%;
  margin-left: 9%;
}
@media (max-width: 1600px) {
  .title22 {
    margin-top: calc(15% + 2px) !important;
  }
}
@media (max-width: 1440px) {
  .title22 {
    font-size: 1.2vw;
    left: auto;
    top: auto;
    margin-top: calc(15% + 2px) !important;
  }
}
.financialDetails:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  margin-top: calc(0px + 2px) !important;
  margin-left: 0px;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (max-width: 1440px) {
  .financialDetails:global(.__wab_instance) {
    margin-top: calc(0px + 2px) !important;
  }
}
.userManagement:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.branding:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.vertStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #f6f7fb;
  min-width: 0;
  min-height: 0;
  padding: 0px;
  margin: 0%;
}
.vertStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 1%);
  height: calc(100% + 1%);
}
.vertStack > :global(.__wab_flex-container) > *,
.vertStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.vertStack > :global(.__wab_flex-container) > picture > img,
.vertStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1%;
}
.billingDetail {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 701px;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-top: calc(69px + 1%) !important;
  margin-right: 0%;
  margin-bottom: 62px;
  margin-left: 0%;
  max-width: none;
  border-radius: 8px;
}
.billingDetail > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.billingDetail > :global(.__wab_flex-container) > *,
.billingDetail > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.billingDetail > :global(.__wab_flex-container) > picture > img,
.billingDetail
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .billingDetail {
    left: auto;
    top: auto;
    width: 48vw;
    margin-top: calc(69px + 1%) !important;
  }
}
.freeBox__lusYv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 78px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__lusYv {
    height: 5vw;
    flex-shrink: 0;
  }
}
.title9 {
  display: block;
  font-size: 21px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title9 {
    font-size: 1.43vw;
  }
}
.switchPlanStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 162px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border-bottom: 0px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .switchPlanStack {
    height: 11vw;
    flex-shrink: 0;
  }
}
.freeBox__zbLau {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 204px;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__zbLau {
    top: auto;
    left: auto;
    margin-left: 4.1vw;
    width: 14vw;
    flex-shrink: 0;
  }
}
.title10 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.5;
  color: #222222;
  width: 204px;
  height: auto;
  margin-bottom: 16px;
}
@media (max-width: 1440px) {
  .title10 {
    font-size: 1.35vw;
    margin-bottom: 1.1vw;
    width: 100%;
    min-width: 0;
  }
}
.freeBox__jm6NM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 204px;
  height: auto;
}
@media (max-width: 1440px) {
  .freeBox__jm6NM {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetCurrentPlan {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #000000;
  line-height: 1.5;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetCurrentPlan {
    font-size: 1.35vw;
  }
}
.switchPlan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 222px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .switchPlan {
    margin-left: 14.5vw;
    width: 12vw;
    height: 3vw;
    flex-shrink: 0;
  }
}
.root .switchPlan:hover {
  background: #3555ffd4;
}
.root .switchPlan:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__p04H1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding-top: 0px;
}
@media (max-width: 1440px) {
  .text__p04H1 {
    font-size: 1.15vw;
  }
}
.planStack {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 30px 0px 0px;
}
.planStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.planStack > :global(.__wab_flex-container) > *,
.planStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.planStack > :global(.__wab_flex-container) > picture > img,
.planStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (max-width: 1440px) {
  .planStack {
    padding-top: 1.6vw;
    max-width: none;
  }
}
.freeBox__va4B1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 621px;
  height: auto;
  max-width: 100%;
  margin-bottom: 20px;
  margin-left: 60px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__va4B1 {
    width: auto;
    margin-left: 4.1vw;
    margin-bottom: 1vw;
  }
}
.title17 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.5;
  color: #222222;
  width: auto;
  height: auto;
}
@media (max-width: 1440px) {
  .title17 {
    font-size: 1.35vw;
  }
}
.closeButton {
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #3555ff;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 403px;
  align-self: auto;
  margin-top: 0px;
  right: auto;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .closeButton {
    font-size: 1vw;
    width: auto;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 27vw;
  }
}
@media (max-width: 1370px) {
  .closeButton {
    left: auto;
    top: auto;
    right: auto;
  }
}
.freeStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 0px;
  margin-top: calc(0px + 10px) !important;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1440px) {
  .freeStack {
    height: 4.2vw;
    margin-left: 4.1vw;
    margin-top: calc(0px + 10px) !important;
    flex-shrink: 0;
  }
}
.freeCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.title18 {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13px;
  align-self: flex-start;
  margin-top: 23px;
}
@media (max-width: 1440px) {
  .title18 {
    font-size: 1vw;
    width: auto;
  }
}
.premiumStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 0px;
  margin-top: calc(0px + 10px) !important;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1440px) {
  .premiumStack {
    height: 4.2vw;
    margin-left: 4.1vw;
    margin-top: calc(0px + 10px) !important;
    flex-shrink: 0;
  }
}
.premiumCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.title15 {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13px;
  align-self: flex-start;
  margin-top: 23px;
}
@media (max-width: 1440px) {
  .title15 {
    font-size: 1vw;
    width: auto;
  }
}
.growthStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 0px;
  margin-top: calc(0px + 10px) !important;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1440px) {
  .growthStack {
    height: 4.2vw;
    margin-left: 4.1vw;
    margin-top: calc(0px + 10px) !important;
    flex-shrink: 0;
  }
}
.growthCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.title16 {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13px;
  align-self: flex-start;
  margin-top: 23px;
  padding-top: 0px;
}
@media (max-width: 1440px) {
  .title16 {
    font-size: 1vw;
    width: auto;
  }
}
.customStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 61px;
  max-width: 100%;
  background: #44c0ff00;
  margin-bottom: 0px;
  margin-top: calc(0px + 10px) !important;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0% 0px 0px;
}
@media (max-width: 1440px) {
  .customStack {
    height: 4.2vw;
    margin-left: 4.1vw;
    margin-top: calc(0px + 10px) !important;
    flex-shrink: 0;
  }
}
.customCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.title12 {
  display: block;
  font-size: 15.4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 13px;
  align-self: flex-start;
  margin-top: 23px;
}
@media (max-width: 1440px) {
  .title12 {
    font-size: 1vw;
    width: auto;
  }
}
.finalizePlan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 60px;
  margin-top: calc(20px + 10px) !important;
  margin-bottom: 25px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .finalizePlan {
    width: 12vw;
    height: 3vw;
    margin-left: 4.1vw;
    margin-top: calc(1vw + 10px) !important;
    margin-bottom: 1.2vw;
    flex-shrink: 0;
  }
}
.root .finalizePlan:hover {
  background: #3555ffd4;
  left: auto;
  top: auto;
  margin-top: calc(20px + 10px) !important;
}
.root .finalizePlan:active {
  background: #3555ffd4;
  left: auto;
  top: auto;
  margin-top: calc(20px + 10px) !important;
  border: 1px solid #000000;
}
.text__p9Shk {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding-top: 0px;
}
@media (max-width: 1440px) {
  .text__p9Shk {
    font-size: 1.15vw;
  }
}
.text__k2Si3 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  margin-left: 60px;
  margin-bottom: 30px;
  margin-top: calc(20px + 0px) !important;
  color: #222222;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .text__k2Si3 {
    margin-top: calc(1.4vw + 0px) !important;
    margin-bottom: 1.9vw;
    margin-left: 4.1vw;
    font-size: 1.03vw;
  }
}
.link {
  text-decoration-line: underline;
  color: #3555ff;
}
.freeBox___23OHf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 162px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  margin-top: calc(10px + 0px) !important;
  min-width: 0;
  flex-shrink: 0;
  padding: 10px 0px 0px;
  border-top: 1px solid #d3d3d8;
  border-bottom: 0px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox___23OHf {
    padding-top: 0.6vw;
    margin-top: calc(0.6vw + 0px) !important;
    height: 11vw;
    flex-shrink: 0;
  }
}
.freeBox__guYlL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 204px;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 0px;
  margin-bottom: 0%;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox__guYlL {
    top: auto;
    left: auto;
    margin-left: 4.1vw;
    width: 14vw;
    flex-shrink: 0;
  }
}
.title13 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.5;
  color: #222222;
  width: 204px;
  height: auto;
  margin-bottom: 16px;
}
@media (max-width: 1440px) {
  .title13 {
    font-size: 1.35vw;
    margin-bottom: 1.1vw;
    width: 100%;
    min-width: 0;
  }
}
.freeBox__zbFXq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 204px;
  height: auto;
}
@media (max-width: 1440px) {
  .freeBox__zbFXq {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetBankName {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #000000;
  line-height: 1.5;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetBankName {
    font-size: 1.35vw;
  }
}
.switchBank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 222px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .switchBank {
    width: 12vw;
    height: 3vw;
    margin-left: 14.5vw;
    flex-shrink: 0;
  }
}
.root .switchBank:hover {
  background: #3555ffd4;
}
.root .switchBank:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text___3JW3Q {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
}
@media (max-width: 1440px) {
  .text___3JW3Q {
    font-size: 1.15vw;
  }
}
.text__pAzJ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  margin-left: 60px;
  margin-bottom: 48px;
  margin-top: calc(20px + 0px) !important;
  color: #222222;
  font-size: 15px;
}
@media (max-width: 1440px) {
  .text__pAzJ {
    font-size: 1.03vw;
    margin-left: 4.1vw;
    margin-top: calc(1.4vw + 0px) !important;
    margin-bottom: 3.1vw;
  }
}
.accountingSoftwareStack {
  box-shadow: inset 0px 0px 1px 1px #cecfd8;
  background: #ffffff;
  display: flex;
  position: relative;
  width: 701px;
  height: auto;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-top: calc(0px + 1%) !important;
  margin-right: 0%;
  margin-bottom: 62px;
  margin-left: 0%;
  max-width: none;
  border-radius: 8px;
}
.accountingSoftwareStack > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.accountingSoftwareStack > :global(.__wab_flex-container) > *,
.accountingSoftwareStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.accountingSoftwareStack > :global(.__wab_flex-container) > picture > img,
.accountingSoftwareStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 1440px) {
  .accountingSoftwareStack {
    left: auto;
    top: auto;
    width: 48vw;
    margin-top: calc(0px + 1%) !important;
  }
}
@media (max-width: 1370px) {
  .accountingSoftwareStack {
    margin-top: calc(0px + 1%) !important;
  }
}
.freeBox__nqEj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 78px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .freeBox__nqEj {
    height: 5vw;
    flex-shrink: 0;
  }
}
.title19 {
  display: block;
  font-size: 21px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 100%;
  color: #000000;
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 1%;
  margin-bottom: 1%;
}
@media (max-width: 1440px) {
  .title19 {
    font-size: 1.43vw;
  }
}
.switchPlanStack2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 162px;
  max-width: 100%;
  background: #44c0ff00;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border-bottom: 0px solid #d3d3d8;
}
@media (max-width: 1440px) {
  .switchPlanStack2 {
    height: 11vw;
    flex-shrink: 0;
  }
}
.freeBox___2Vokv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 204px;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 60px;
  flex-shrink: 0;
  padding: 0px;
}
@media (max-width: 1440px) {
  .freeBox___2Vokv {
    top: auto;
    left: auto;
    margin-left: 4.1vw;
    width: 14vw;
    flex-shrink: 0;
  }
}
.title20 {
  display: block;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.5;
  color: #222222;
  width: 204px;
  height: auto;
  margin-bottom: 16px;
}
@media (max-width: 1440px) {
  .title20 {
    font-size: 1.35vw;
    margin-bottom: 1.1vw;
    width: 100%;
    min-width: 0;
  }
}
.freeBox___2Fo6E {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 204px;
  height: auto;
}
@media (max-width: 1440px) {
  .freeBox___2Fo6E {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetAccountingSoftware {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #000000;
  line-height: 1.5;
  letter-spacing: 0.3px;
}
@media (max-width: 1440px) {
  .slotTargetAccountingSoftware {
    font-size: 1.35vw;
  }
}
.linkAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 222px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .linkAccount {
    margin-left: 14.5vw;
    width: 12vw;
    height: 3vw;
    flex-shrink: 0;
  }
}
.root .linkAccount:hover {
  background: #3555ffd4;
}
.root .linkAccount:active {
  background: #3555ffd4;
  border: 1px solid #000000;
}
.text__iGO4 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding-top: 0px;
}
@media (max-width: 1440px) {
  .text__iGO4 {
    font-size: 1.15vw;
  }
}
.inviteUsersStack {
  box-shadow: inset 0px 0px 1px 1px #c7c7c7;
  background: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 701px;
  height: auto;
  max-width: 100%;
  margin-bottom: 40px;
  margin-top: calc(69px + 1%) !important;
  border-radius: 8px;
  padding: 0px;
  border: 1px solid #f4f4f4;
}
@media (max-width: 1440px) {
  .inviteUsersStack {
    margin-top: calc(69px + 1%) !important;
  }
}
@media (max-width: 1370px) {
  .inviteUsersStack {
    width: 48vw;
    margin-top: calc(69px + 1%) !important;
  }
}
.freeBox__yCn3R {
  display: flex;
  position: relative;
  width: 100%;
  height: 75px;
  max-width: 100%;
  margin-bottom: 22px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dcdcdc;
}
.text___0Nj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.inviteUsersHeading {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
}
.freeBox__jbMp0 {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 20px 8px 0px;
  border-bottom: 0px solid #cbc9c9;
}
.inviteUsersBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 43px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 340px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
@media (max-width: 1440px) {
  .inviteUsersBtn {
    margin-left: 23.7vw;
    width: 12vw;
    height: 3vw;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
@media (max-width: 1370px) {
  .inviteUsersBtn {
    margin-left: 21vw;
  }
}
.root .inviteUsersBtn:hover {
  background: #3555ffd4;
  left: auto;
  top: auto;
}
.root .inviteUsersBtn:active {
  background: #3555ffd4;
  left: auto;
  top: auto;
  border: 1px solid #000000;
}
.text__kmR9 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding-top: 0px;
}
@media (max-width: 1440px) {
  .text__kmR9 {
    font-size: 1.15vw;
  }
}
.text__padi {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 60px;
  color: #222222;
  font-weight: 600;
  font-size: 19px;
}
.h6__abxFl {
  width: auto;
  font-size: 19px;
  color: #222222;
  font-weight: 600;
}
.freeBox__nlBeh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 15px;
}
.userListStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 0px;
}
.inviteUserCard___5SXH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.text__ylfiT {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
.brandingStack {
  box-shadow: inset 0px 0px 1px 1px #c7c7c7;
  background: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 701px;
  height: auto;
  max-width: 100%;
  margin-bottom: 40px;
  margin-top: calc(69px + 1%) !important;
  border-radius: 8px;
  padding: 0px;
  border: 1px solid #f4f4f4;
}
@media (max-width: 1440px) {
  .brandingStack {
    margin-top: calc(69px + 1%) !important;
  }
}
@media (max-width: 1370px) {
  .brandingStack {
    width: 48vw;
    margin-top: calc(69px + 1%) !important;
  }
}
.freeBox__neARd {
  display: flex;
  position: relative;
  width: 100%;
  height: 75px;
  max-width: 100%;
  margin-bottom: 22px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #dcdcdc;
}
.text___8DpMo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.inviteUsersHeading2 {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
}
.freeBox__f4Yaa {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  padding: 8px 20px 8px 0px;
  border-bottom: 0px solid #cbc9c9;
}
.logo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 54px;
  left: auto;
  top: auto;
  border-radius: 4px;
}
.logo > picture > img {
  object-fit: cover;
}
.text__f73Aw {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 60px;
  color: #222222;
  font-weight: 600;
  font-size: 19px;
  left: auto;
  top: auto;
}
.h6__k2AT6 {
  width: auto;
  font-size: 19px;
  color: #222222;
  font-weight: 600;
}
.freeBox__kDlu3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 15px;
}
.colorPickerContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 20px 8px 0px;
}
.inviteUserCard__mc2BP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.text__mgdY4 {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
.freeBox__lEf9F {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 15px;
}
.saveStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 20px 8px 0px;
}
.handleSubmit:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2K3Et {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rdEuh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
