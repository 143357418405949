.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #3555ff;
  min-width: 0;
  min-height: 0;
}
.previewBody {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__qkIaL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #44c0ff00;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.companyName {
  display: flex;
  width: 400px;
  height: 58px;
  margin-bottom: 2%;
  padding-top: 0px;
  margin-top: 0%;
  justify-content: center;
  flex-direction: row;
  flex-shrink: 0;
}
.slotTargetMerchantName {
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.2px;
}
.rectangle29 {
  background: #ffffff;
  width: 392px;
  height: 133px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  margin-bottom: 2%;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  margin: 0px;
}
.freeBox___6VYwi {
  display: flex;
  position: absolute;
  width: 361px;
  left: 22px;
  top: 15px;
  height: auto;
}
.slotTargetEscrowDeposit {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox___2ECe {
  display: flex;
  position: absolute;
  width: 361px;
  left: 22px;
  top: 58px;
  height: auto;
}
.slotTargetEscrowDeposit2 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.svg__eJxZs {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.9990125124341537, 0.9990075074918353)
    rotate(-0.28676326876410735deg) skew(-0.05735167682688388deg, 0deg);
  width: 241px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 21px;
  top: 98.5px;
}
.viewDetails {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(95, 86, 86, 1);
  position: absolute;
  left: 26px;
  top: 109px;
  cursor: pointer;
}
.rectangle28 {
  background: rgba(255, 255, 255, 1);
  width: 395px;
  height: 315px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 10px;
}
.figmaPaste2 {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  min-width: 0;
  min-height: 0;
}
.bankTransferButton:global(.__wab_instance) {
  position: absolute;
  top: 15px;
  left: 32px;
  height: 64px;
}
.svg__jDj1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 29px;
  top: 94px;
}
.text___0I3Qb {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 193px;
  height: 15px;
  position: absolute;
  left: 112px;
  top: 114px;
}
.freeBox__igKru {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 372px;
  height: 165px;
  max-width: 100%;
  background: rgba(68, 192, 255, 0.2);
  left: 15px;
  top: 139px;
  padding: 8px;
}
.figmaPaste3 {
  display: flex;
  position: relative;
  width: 337px;
  height: 127px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.text__mRrz {
  display: block;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-top: 46px;
}
.sideBarInstance {
  display: block;
  position: relative;
  width: 431px;
  height: 100%;
  left: auto;
  top: auto;
  background: #ffffff;
  flex-shrink: 0;
  min-height: 0;
}
.freeBox__gusRl {
  display: flex;
  position: absolute;
  width: 256px;
  left: 44px;
  top: 139px;
}
.slotTargetContractName {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.freeBox___9LYzr {
  display: flex;
  position: absolute;
  width: 151px;
  left: 44px;
  top: 57px;
}
.slotTargetAmount {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.closeButton {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: auto;
  position: absolute;
  left: 382px;
  top: 27px;
  cursor: pointer;
}
.text__iraLt {
  display: block;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(112, 112, 112, 1);
  width: 63px;
  position: absolute;
  left: 319px;
  top: 142px;
}
.milestoneNameStack {
  display: flex;
  position: absolute;
  width: 329px;
  left: 44px;
  top: 214px;
}
.slotTargetMilestoneName {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 100%;
  letter-spacing: 0.2px;
}
.svg__no1YO {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 38px;
  top: 289px;
}
.text__jVtX5 {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  width: 194px;
  position: absolute;
  left: 128px;
  top: 347px;
}
.svg__gs3Y {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: scale(0.999, 0.999) skew(-0.05735311348973583deg, 0deg);
  width: 336px;
  height: 1px;
  display: block;
  transform-origin: top left;
  left: 44px;
  top: 567px;
}
.downloadContract {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #44c0ff00;
  left: 166px;
  top: 411px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
  padding: 0px;
}
.svg__cRDkn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(0.40000000000000036px, -0.08099999999999996px);
  width: 4vw;
  height: 5vw;
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.freeBox___5Gssk {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1.6vw;
  height: 1.7vw;
  max-width: 100%;
  background: #ffffff;
  left: 68.83%;
  top: 76.04%;
  flex-shrink: 0;
  border-radius: 1000px;
  padding: 0px;
  border-width: 1px;
  border-style: solid;
}
.svg__lX5Hy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: 1em;
  flex-shrink: 0;
}
