.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.header {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  max-width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
  border-bottom: 1px solid #d3d3d3e6;
}
.text___749Vl {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  font-size: 22px;
}
.text__bsdkK {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  margin-left: 418px;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  color: #3555ff;
  font-weight: 600;
  align-self: center;
}
.freeBox__phlL1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 0px 10px 10px;
}
.text__iMc8 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
}
.city {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 16.4px;
  width: 100%;
  height: 40px;
  max-width: 100%;
  margin-top: 22px;
  color: #535353;
  font-family: "Open Sans", sans-serif;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 2px 2px 2px 12px;
  border: 0px solid #d3d3d3;
}
.text__g2Wko {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 38px;
}
.amount {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 255px;
  height: 40px;
  max-width: 100%;
  background: #ffffff;
  margin-top: 22px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__bl30E {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 38px;
}
.dueDate {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  max-width: 100%;
  background: #ffffff;
  margin-top: 22px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__je2YU {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 38px;
}
.contractDescription {
  box-shadow: inset 0px 0px 0px 1px rgba(163, 163, 163, 1);
  font-size: 14px;
  position: relative;
  width: 100%;
  height: 120px;
  max-width: 100%;
  left: auto;
  top: auto;
  background: #ffffff;
  margin-top: 22px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 5px 2px 2px 12px;
  border: 0px solid #d3d3d3;
}
.freeBox__sNinL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  max-width: 100%;
  align-self: center;
  margin-top: 15px;
  border-top-color: #d3d3d3e6;
  border-top-style: solid;
  min-width: 0;
  flex-shrink: 0;
  padding: 15px 0px 0px;
  border-width: 1px;
}
.freeBox___8QXzt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 50px;
  max-width: 100%;
  background: #3555ff;
  cursor: pointer;
  position: relative;
  align-self: center;
  margin-top: 0px;
  margin-right: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0px;
}
.text__a9Hzi {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  margin-top: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 0px;
}
