.root {
  background: #c2c2c2d9;
  width: 4.1vw;
  height: 1.2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100px;
}
.text {
  display: block;
  font-size: 0.65vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 100%;
  color: #595959;
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  margin-top: 0vw;
}
